import { useEffect, useState } from 'react';
import { IValidator } from '../../../entities/validator';
import { IBlock, IBlockSignature } from '../../../entities/block';
import { TTransaction } from '../../../entities/transaction';
import { IVote } from '../../../entities/vote';
import { IDelegation } from '../../../shared';

export const useTableSearch = (
  data:
    | (
        | IValidator
        | IBlock
        | TTransaction
        | IVote
        | string
        | IDelegation
        | IBlockSignature
      )[]
    | undefined,
  filterFn: (
    obj:
      | IValidator
      | IBlock
      | TTransaction
      | IVote
      | string
      | IDelegation
      | IBlockSignature
  ) => boolean
) => {
  const [searchInput, setSearchInput] = useState('');
  const [searchIsOpened, setSearchIsOpened] = useState(false);
  const [filteredData, setFilteredData] = useState(data);

  const handleChangeSearchInput = (value: string) => {
    setSearchInput(value);
  };

  const handleOpenSearch = (isOpened: boolean) => {
    setSearchIsOpened(isOpened);
    if (!isOpened) {
      setSearchInput('');
    }
  };

  useEffect(() => {
    if (searchInput === '') {
      setFilteredData(data);
    } else {
      if (data) {
        const filtered = data.filter(filterFn);
        setFilteredData(filtered);
      }
    }
  }, [searchInput, data]);

  return {
    searchInput,
    searchIsOpened,
    handleChangeSearchInput,
    handleOpenSearch,
    filteredData
  };
};
