import { Line } from 'rc-progress';
import { useState } from 'react';
import { validatorsStore } from '../../../entities/validator';
import { DataCardHeader, formatNumber } from '../../../shared';

type VotingPowerBlockProps = {
  amount: number;
  percentage: number;
};

export const VotingPowerBlock = ({
  amount,
  percentage
}: VotingPowerBlockProps) => {
  return (
    <div className="flex flex-col justify-between h-full">
      <DataCardHeader>Voting power</DataCardHeader>
      <div className="flex flex-col justify-between">
        <div className="flex items-center justify-between mb-3">
          <div>{formatNumber(amount)}</div>
          <div>{percentage.toFixed(2)}%</div>
        </div>
        <Line
          percent={percentage}
          strokeWidth={1}
          strokeColor="#000"
          trailColor="yellow"
          trailWidth={100}
          strokeLinecap="round"
          className="h-3 w-full rounded-full"
        />
      </div>
    </div>
  );
};
