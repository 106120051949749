import {
  BoxIcon,
  IconPropsType,
  activeClasses,
  handleCopyValue
} from '../../../shared';
import clsx from 'clsx';

type ContactBlockProps = {
  icon: IconPropsType;
  info: string | undefined;
  isLink?: boolean;
  isMail?: boolean;
};

const classes =
  'border-b border-transparent hover:border-b hover:border-primary truncate';

export const ContactBlock = ({
  icon,
  info,
  isLink,
  isMail
}: ContactBlockProps) => {
  // TODO: Fix links without protocol
  return (
    <div className="flex items-center space-x-4 lg:space-x-8 text-lg lg:text-xl">
      <BoxIcon size={36} borderWidth={1} icon={icon} />
      {info && isLink ? (
        <a
          href={`${isMail ? 'mailto:' : ''}${info}`}
          title={info}
          className={clsx(classes, activeClasses)}
          rel="noreferrer"
          target="_blank"
        >
          {info}
        </a>
      ) : (
        <button
          onClick={() => handleCopyValue(info ?? '')}
          title={info && 'Click to copy'}
          disabled={Boolean(info)}
          className={clsx(
            classes,
            activeClasses,
            info ? 'cursor-pointer' : 'cursor-default'
          )}
        >
          {info || 'N/A'}
        </button>
      )}
    </div>
  );
};
