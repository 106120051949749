import { useEffect, useState } from 'react';
import { IValidator, validatorsStore } from '../../../entities/validator';
import { NumbersCell } from './NumbersCell';

export const ValidatorStatus = (validator: IValidator) => {
  const [uptime, setUptime] = useState<number | undefined>();
  const [status, setStatus] = useState<'Active' | 'Inactive' | 'Jailed'>(
    'Inactive'
  );

  const {
    activeValidators,
    getValidatorUptime,
    validatorUptimeLoading,
    validatorsLoading
  } = validatorsStore();

  const handleGetStatus = async () => {
    if (validator.jailed) {
      setStatus('Jailed');
      return;
    }

    const uptime = await getValidatorUptime(validator.tendermint_key);
    setUptime(uptime);

    const val = activeValidators.find((v) => v.address === validator.address);

    if (val && uptime !== 0) setStatus('Active');
  };

  useEffect(() => {
    if (validator && validator.tendermint_key && !uptime) {
      handleGetStatus();
    }
  }, [validator.tendermint_key]);

  return (
    <div className="h-full grid grid-cols-2 grid-rows-2 xl:flex xl:flex-row justify-around items-center gap-3">
      <NumbersCell
        title="Status"
        value={status}
        isJailed={status === 'Jailed'}
        isLoading={validatorUptimeLoading}
      />
      <NumbersCell
        title="Commission"
        value={
          validator.commission_rate
            ? `${(Number(validator.commission_rate) * 100).toFixed(0)}%`
            : '0%'
        }
        isLoading={validatorsLoading}
      />
      <NumbersCell
        title="Max Change"
        value={validator.max_change ? `${validator.max_change * 100}%` : '0%'}
        isLoading={validatorsLoading}
      />
      <NumbersCell
        title="Uptime"
        value={uptime ? `${uptime * 100}%` : '0%'}
        isLoading={validatorUptimeLoading}
      />
    </div>
  );
};
