import { PROPOSALS_URL, VALOPERS_URL, httpService } from '../../../shared';
import { IProposalV1, IProposal, IProposalData } from '../model';

export const proposalsApi = {
  getProposals: async (status: string, page: number): Promise<IProposal> => {
    const data = await httpService.get(
      VALOPERS_URL + `/getAllProposals?status=${status}&page=${page}&limit=16`
    );

    return data;
  },
  getProposal: async (id: string): Promise<IProposalData> => {
    const data = await httpService.get(VALOPERS_URL + '/getProposal/' + id);

    return data;
  }
};
