import { PropsWithChildren } from 'react';
import { DataCardHeader } from './DataCardHeader';

type DataWChildrenProps = {
  title: string;
};

export const DataWChildren = ({
  children,
  title
}: PropsWithChildren<DataWChildrenProps>) => {
  return (
    <div className="flex flex-col justify-between h-full">
      <DataCardHeader>{title}</DataCardHeader>

      {children}
    </div>
  );
};
