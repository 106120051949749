import { navRoutes } from '../../../app/Router';

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const getPageName = (pathname: string) => {
  const path = navRoutes.find(({ path }) => path === pathname);

  if (path) {
    return path.title;
  } else {
    return '';
  }
};
