import { voteStore } from '../../../entities/vote';
import { Card } from '../../../shared';
import { TabSwitcher, useTabs } from '../../../features/tab-switcher';
import { ProposalVotesTab } from './ProposalVotesTab';

const tabs = ['Yay', 'Nay', 'Abstain', 'Did not vote'];

export const ProposalVotes = () => {
  const { proposalVotes, proposalVotesLoading } = voteStore();

  const { currentTab, handleChangeTab } = useTabs(tabs);

  return (
    <div>
      <div className="mb-4">
        <TabSwitcher
          currentTab={currentTab}
          onChange={handleChangeTab}
          tabs={tabs}
        />
      </div>
      <Card>
        {proposalVotesLoading ? (
          <div className="h-80 overflow-y-scroll">
            <div className="bg-neutral-200 w-full h-full animate-pulse rounded-lg"></div>
          </div>
        ) : (
          <>
            {proposalVotes && (
              <>
                {currentTab === 'Yay' && (
                  <ProposalVotesTab votes={proposalVotes.yay} />
                )}
                {currentTab === 'Nay' && (
                  <ProposalVotesTab votes={proposalVotes.nay} />
                )}
                {currentTab === 'Abstain' && (
                  <ProposalVotesTab votes={proposalVotes.abstain} />
                )}
                {currentTab === 'Did not vote' && (
                  <ProposalVotesTab votes={proposalVotes.did_not_vote} />
                )}
              </>
            )}
          </>
        )}
      </Card>
    </div>
  );
};
