import { StoreApi, create } from 'zustand';
import { IValidator } from './validatorTypes';
import { computed } from 'zustand-middleware-computed-state';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { IDelegations } from '../../../shared/';
import { validatorApi } from '../api';

type Store = {
  activeValidators: IValidator[];
  inactiveValidators: IValidator[];
  allValidators: IValidator[];
  totalBonded: number | null;
  validatorsError: string | null;
  validatorsLoading: boolean;
  validatorUptime: number | null;
  validatorUptimeLoading: boolean;
  uptimeIndex: number;
  valDelegators: IDelegations | null;
  valDelegatorsLoading: boolean;
  valDelegatorsError: string | null;
};

type Actions = {
  getValidators: () => Promise<void>;
  findValidatorByAddress: (address: string) => IValidator;
  findValidatorAddressByTendermint: (key: string) => string | undefined;
  findValidatorByTendermint: (key: string) => IValidator | undefined;
  getValidatorUptime: (key: string) => Promise<number | undefined>;
  setUptimeIndex: () => void;
  getValDelegations: (address: string) => void;
};

type ValidatorsStore = Store & Actions;

type ComputedStore = {
  topValidators: IValidator[];
  activeValspercent: number;
};

const computedStore = (state: ValidatorsStore): ComputedStore => {
  const activeValspercent =
    state.allValidators.length > 0
      ? (state.activeValidators.length * 100) / state.allValidators.length
      : 0;

  const topValidators = [...state.allValidators];
  topValidators.length = 15;

  return {
    topValidators,
    activeValspercent
  };
};

export const validatorsStore = create<ValidatorsStore & ComputedStore>(
  computed<ValidatorsStore, ComputedStore>(
    (
      set: StoreApi<ValidatorsStore>['setState'],
      get: StoreApi<ValidatorsStore>['getState']
    ) => ({
      activeValidators: [],
      inactiveValidators: [],
      allValidators: [],
      totalBonded: 0,
      validatorsError: null,
      validatorsLoading: true,
      validatorUptime: null,
      validatorUptimeLoading: true,
      uptimeIndex: 0,
      valDelegators: null,
      valDelegatorsLoading: true,
      valDelegatorsError: null,
      setUptimeIndex: () => {
        set((state) => ({ uptimeIndex: state.uptimeIndex + 1 }));
      },
      getValidators: async () => {
        set({
          validatorsError: null
        });

        try {
          const validators = await validatorApi.getValidators();

          const {
            active: activeValidators,
            inactive: inactiveValidators,
            totalBonded
          } = validators;

          set({
            activeValidators,
            inactiveValidators,
            totalBonded,
            allValidators: [...activeValidators, ...inactiveValidators]
          });
        } catch (error) {
          if (error instanceof AxiosError) {
            console.error(error);
            const message =
              'Something went wrong when we tried to get validators';
            set({
              validatorsError: message
            });
            toast.error(message);
          }
        } finally {
          set({ validatorsLoading: false });
        }
      },
      findValidatorByAddress: (address: string) => {
        const valsTogether = get().allValidators;

        const validator = valsTogether.find((v) => v.address === address);

        return validator as IValidator;
      },
      findValidatorByTendermint: (key) => {
        const validators = get().allValidators;

        const validator = validators.find((v) => {
          return v.tendermint_key === key;
        });

        return validator;
      },
      findValidatorAddressByTendermint: (key) => {
        const validators = get().allValidators;

        const validator = validators.find((v) => {
          return v.tendermint_key === key;
        });

        return validator?.address;
      },
      getValidatorUptime: async (key) => {
        set({
          validatorUptimeLoading: true
        });
        try {
          const { uptimePercentage } = await validatorApi.getUptimeValidator(
            key
          );

          return uptimePercentage;
        } catch (error) {
          const message =
            'Something went wrong when we tried to get this validator uptime';
          toast.error(message);
        } finally {
          set({ validatorUptimeLoading: false });
        }
      },
      getValDelegations: async (address) => {
        set({
          valDelegatorsLoading: true,
          valDelegatorsError: null
        });
        try {
          const valDelegations = await validatorApi.getValDelegators(address);

          set({ valDelegators: valDelegations });
        } catch (error) {
          const message =
            'Something went wrong when we tried to get this validator delegations';
          toast.error(message);
        } finally {
          set({ valDelegatorsLoading: false });
        }
      }
    }),
    computedStore
  )
);
