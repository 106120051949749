import { DataWLoader } from '../../../shared';
import { blockStore } from '../../../entities/block';
import { useTimeDiff } from '../model';

export const BlockTime = () => {
  const { statusError } = blockStore();

  const { timeDiff } = useTimeDiff();

  return (
    <DataWLoader
      name="Block time"
      data={`${timeDiff ?? '-'} seconds`}
      isLoading={Boolean(!timeDiff)}
      error={statusError}
    />
  );
};
