import { ENVIRONMENT } from '../config/config';

export const logger = {
  debug: (label: string, data: any) => {
    if (ENVIRONMENT === 'development') return console.log(label, data);
  },
  info: (label: string, data: any) => {
    return console.log(label, data);
  },
  error: (label: string, data: any) => {
    if (ENVIRONMENT === 'development') return console.error(label, data);
  }
};
