import { KeyboardEvent, KeyboardEventHandler, useState } from 'react';
import { useSearch } from '../model';
import { IoSearchOutline } from 'react-icons/io5';
import clsx from 'clsx';
import { Loader, activeClasses } from '../../../shared';
import { accountStore } from '../../../entities/account';

type SearchBarProps = { className?: string };

export const SearchBar = ({ className }: SearchBarProps) => {
  const { searchData, error, searchInput, handleInput } = useSearch();

  const handleSearchByKey = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      searchData();
    }
  };

  return (
    <div
      className={clsx(
        'rounded-full shadow-[0px_0px_10px_rgba(50,_50,_50,_12%)] bg-gradient-to-b from-neutral-200/10 via-neutral-200/10 to-neutral-500/30 md:mr-0 md:ml-auto lg:mx-0',
        className
      )}
    >
      <div
        className="flex items-center bg-white pl-4 pr-3 rounded-full m-[1px] w-[calc(100%-2px)] h-[calc(100%-2px)] relative"
        onKeyDown={(e) => handleSearchByKey(e)}
      >
        <button onClick={searchData} className="mr-2">
          <IoSearchOutline
            className={clsx(
              'block text-neutral-500 text-lg xl:text-xl',
              activeClasses
            )}
          />
        </button>
        <input
          value={searchInput}
          onChange={(e) => handleInput(e.target.value)}
          placeholder="validator / address / tx hash / block height"
          className={clsx(
            'w-full lg:w-76 xl:w-96 text-neutral-500 py-1 px-2 rounded-full focus:outline-transparent text-sm'
          )}
        />
        {error && (
          <div className="-top-6 left-[52px] absolute text-red-500 text-xs">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};
