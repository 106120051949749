export const EmptyVotesBar = () => {
  return (
    <div
      className="w-full h-5  bg-black flex items-center overflow-hidden rounded-full shadow-[0px_1px_1px_rgba(50,_50,_50,_50%)]"
      title="no votes"
    >
      no votes
    </div>
  );
};
