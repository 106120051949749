import clsx from 'clsx';
import { IDelegation, TableCell, formatNumber } from '../../../shared';
import ProposerCell from '../../../shared/ui/ProposerCell';

export const DelegationsRow = (delegation: IDelegation) => {
  return (
    <>
      <ProposerCell address={delegation.address} className="w-1/2 pr-4" />
      <TableCell
        className={clsx(
          'py-4 text-left w-1/2',
          delegation.amount === '-' && 'text-transparent'
        )}
      >
        {formatNumber(delegation.amount, true)}
      </TableCell>
    </>
  );
};
