import { useParams } from 'react-router';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Link } from 'react-router-dom';

import {
  LayoutWithLoaderAndError,
  Header,
  LinkWrap,
  Card,
  DataWChildren,
  DataCardHeader,
  Nothing,
  CardSearch
} from '../../../shared/ui';
import { validatorsStore, ValidatorMoniker } from '../../../entities/validator';

import { useBlockData } from '../../../features/get-block-data';
import {
  ACCOUNT_ROUTE,
  Data,
  TRANSACTIONS_ROUTE,
  activeClasses,
  formatNumber,
  handleCopyValue,
  hideHashPart
} from '../../../shared';
import { PageHeader } from '../../../widgets/page-header';
import { FaRegCopy } from 'react-icons/fa';
import clsx from 'clsx';
import { useTableSearch } from '../../../features/table-search';
import { IBlockSignature } from '../../../entities/block';

dayjs.extend(relativeTime);

export const BlockPage = () => {
  const {
    findValidatorByTendermint,
    findValidatorByAddress,
    findValidatorAddressByTendermint
  } = validatorsStore();

  const { blockData, blockDataLoading, blockDataError, getBlockData } =
    useBlockData();
  const { height } = useParams();

  useEffect(() => {
    if (!blockData || blockData.header.height !== height) {
      getBlockData(Number(height));
    }
  }, []);

  const { searchInput, handleChangeSearchInput, filteredData } = useTableSearch(
    blockData?.signatures,
    (s) => {
      if (typeof s === 'object' && 'validator_address' in s) {
        const moniker = findValidatorByTendermint(s.validator_address)?.moniker;
        if (moniker) {
          return (
            s.validator_address
              .toLowerCase()
              .includes(searchInput.toLowerCase()) ||
            moniker.toLowerCase().includes(searchInput.toLowerCase())
          );
        } else {
          const address = findValidatorAddressByTendermint(s.validator_address);

          return address
            ? address.toLowerCase().includes(searchInput.toLowerCase()) ||
                s.validator_address
                  .toLowerCase()
                  .includes(searchInput.toLowerCase())
            : s.validator_address
                .toLowerCase()
                .includes(searchInput.toLowerCase());
        }
      } else return false;
    }
  );

  return (
    <>
      <LayoutWithLoaderAndError
        isLoading={blockDataLoading}
        error={blockDataError}
      >
        {blockData && (
          <>
            <PageHeader>Block details</PageHeader>

            <div className="flex flex-col md:flex-row items-center gap-4 xl:gap-6 w-full mb-4 xl:mb-6">
              <Card className="w-full md:w-2/12">
                <Data title="Height" value={formatNumber(height as string)} />
              </Card>
              <Card className="w-full md:w-3/12">
                <DataCardHeader>Proposer</DataCardHeader>

                {blockData.proposer ? (
                  <ValidatorMoniker
                    className="text-lg xl:text-xl"
                    validator={blockData.proposer}
                    truncate
                  />
                ) : (
                  <div className="text-lg xl:text-xl truncate w-full">
                    {blockData.header.proposer_address}
                  </div>
                )}
              </Card>
              <Card className="w-full md:w-7/12">
                <DataWChildren title="Time">
                  <div className="md:text-lg xl:text-xl flex justify-between items-center">
                    <div>
                      {dayjs(blockData.header.time).format(
                        'YYYY-MM-DD HH:mm:ss'
                      )}
                    </div>
                    <div>{dayjs(blockData.header.time).fromNow()}</div>
                  </div>
                </DataWChildren>
              </Card>
            </div>

            <div className="w-full flex flex-col md:flex-row items-center gap-4 xl:gap-6 mb-8 md:mb-12">
              <Card className="w-full md:w-4/12">
                <Data
                  title="Transactions"
                  value={blockData.tx_hashes.length.toString()}
                />
              </Card>
              <Card className="w-full md:w-8/12">
                <DataCardHeader>
                  <div className="flex items-center space-x-2">
                    <div>Block hash</div>
                    <button onClick={() => handleCopyValue(blockData.block_id)}>
                      <FaRegCopy
                        className={clsx('md:text-lg xl:text-xl', activeClasses)}
                      />
                    </button>
                  </div>
                </DataCardHeader>

                <div
                  className="md:text-lg xl:text-xl truncate uppercase"
                  title={blockData.block_id}
                >
                  {blockData.block_id}
                </div>
              </Card>
            </div>

            {/* Signatures */}
            <div>
              <DataCardHeader mb="mb-4 xl:mb-6">Signatures</DataCardHeader>
              <Card padding="p-4 xl:p-6 pr-2">
                <CardSearch
                  searchInput={searchInput}
                  onChange={handleChangeSearchInput}
                  placeholder="address, tendermint key or moniker"
                />
                <div className="h-[500px] overflow-y-scroll">
                  <div className="md:grid md:grid-cols-2 lg:grid-cols-3 gap-2">
                    {filteredData &&
                      (filteredData as IBlockSignature[]).map((s) => {
                        const validator = findValidatorByTendermint(
                          s.validator_address
                        );

                        return (
                          <div
                            className="flex items-center space-x-2 mb-2 truncate"
                            key={s.validator_address}
                          >
                            {validator ? (
                              <ValidatorMoniker
                                validator={validator}
                                truncate
                                className=""
                              />
                            ) : (
                              <LinkWrap
                                to={ACCOUNT_ROUTE + '/' + s.validator_address}
                              >
                                {hideHashPart(s.validator_address)}
                              </LinkWrap>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </Card>
            </div>

            {/* Transactions */}
            <div className="mt-10">
              <DataCardHeader mb="mb-4 xl:mb-6">Transactions</DataCardHeader>
              <Card>
                <div className="space-y-4">
                  {blockData.tx_hashes && blockData.tx_hashes.length > 0 ? (
                    blockData.tx_hashes.map((tx) => (
                      <div
                        className="font-medium md:text-lg xl:text-xl"
                        key={tx.hash_id}
                      >
                        <div className="mb-2 md:mb-4 uppercase">
                          {tx.tx_type}
                        </div>
                        <LinkWrap
                          className="block md:text-xl font-normal truncate"
                          to={`${TRANSACTIONS_ROUTE}/${tx.hash_id}`}
                        >
                          {tx.hash_id}
                        </LinkWrap>
                      </div>
                    ))
                  ) : (
                    <Nothing />
                  )}
                </div>
              </Card>
            </div>
          </>
        )}
      </LayoutWithLoaderAndError>
    </>
  );
};
