import { FaGlobe, FaDiscord } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

import { ContactBlock } from './ContactBlock';
import { IValidator } from '../../../entities/validator';

export const ValidatorContacts = (validator: IValidator) => {
  return (
    <div className="h-full space-y-4 flex flex-col justify-center">
      <ContactBlock icon={FaGlobe} info={validator.website} isLink />
      <ContactBlock icon={MdEmail} info={validator.email} isLink isMail />
      <ContactBlock icon={FaDiscord} info={validator.discord} />
    </div>
  );
};
