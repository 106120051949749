import { useEffect, useState } from 'react';
import { VotesNumber } from '../model/proposalTypes';
import { countVotesPercentage } from '../lib';

export const VotesBar = (votes: VotesNumber) => {
  const [percentage, _] = useState<VotesNumber>(countVotesPercentage(votes));

  return (
    <div className="flex items-center overflow-hidden rounded-full shadow-[0px_1px_1px_rgba(50,_50,_50,_50%)]">
      <div
        className="h-5 bg-energy-green"
        title={votes.yay.toString()}
        style={{ width: `${percentage.yay}%` }}
      ></div>
      <div
        className="h-5 bg-energy-red"
        title={votes.nay.toString()}
        style={{ width: `${percentage.nay}%` }}
      ></div>
      <div
        className="h-5 bg-neutral-300"
        title={votes.abstain.toString()}
        style={{ width: `${percentage.abstain}%` }}
      ></div>
    </div>
  );
};
