import { useEffect, useState } from 'react';
import {
  validatorsStore,
  ValidatorMoniker,
  IValidator
} from '../../entities/validator';
import { TableCell } from './Table';
import clsx from 'clsx';
import { ACCOUNT_ROUTE } from '../config';
import { LinkWrap } from './LinkWrap';

type ProposerCellProps = {
  address: string;
  className?: string;
};

const ProposerCell = ({ address, className }: ProposerCellProps) => {
  const {
    activeValidators,
    findValidatorByTendermint,
    findValidatorByAddress
  } = validatorsStore();
  const [proposer, setProposer] = useState<IValidator | undefined>(
    findValidatorByTendermint(address) ?? findValidatorByAddress(address)
  );

  useEffect(() => {
    if (!proposer) {
      let val = findValidatorByTendermint(address);

      if (!val) {
        val = findValidatorByAddress(address);
      }
      setProposer(val);
    }
  }, [activeValidators]);

  return (
    <TableCell className={clsx('py-4 break-words truncate', className)}>
      {proposer ? (
        <ValidatorMoniker validator={proposer} truncate={true} />
      ) : (
        <LinkWrap to={ACCOUNT_ROUTE + '/' + address}>{address}</LinkWrap>
      )}
    </TableCell>
  );
};

export default ProposerCell;
