import { useState } from 'react';

export const useTabs = (tabs: string[]) => {
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const handleChangeTab = (tab: string) => {
    setCurrentTab(tab);
  };

  return {
    currentTab,
    handleChangeTab
  };
};
