import { IDelegations, VALOPERS_URL, httpService } from '../../../shared';
import { IValidators } from '../model';

export const validatorApi = {
  getValidators: async (): Promise<IValidators> =>
    await httpService.get(VALOPERS_URL + '/getValidators'),
  getValDelegators: async (address: string): Promise<IDelegations> =>
    await httpService.get(VALOPERS_URL + '/getDelegators/' + address),
  getUptimeValidator: async (
    address: string
  ): Promise<{ uptimePercentage: number }> =>
    await httpService.post(VALOPERS_URL + '/getUptimeValidator', {
      validator_address: address,
      blocks: 100
    })
};
