import { IBlock } from '../model';
import dayjs from 'dayjs';
import { formatNumber, hideHashPart } from '../../../shared/lib/utils';
import ProposerCell from '../../../shared/ui/ProposerCell';
import { BLOCKS_ROUTE, LinkWrap, TableCell } from '../../../shared';

export const BlockRow = (block: IBlock) => {
  return (
    <>
      <TableCell className="w-12 whitespace-nowrap pr-4">
        <LinkWrap to={`${BLOCKS_ROUTE}/${block.block_height}`}>
          {formatNumber(block.block_height)}
        </LinkWrap>
      </TableCell>
      <TableCell className="pr-4">
        <LinkWrap to={`${BLOCKS_ROUTE}/${block.block_height}`}>
          {hideHashPart(block.block_id)}
        </LinkWrap>
      </TableCell>
      <ProposerCell address={block.proposer_address} className="pr-4" />
      <TableCell className="pr-4">{block.total_tx}</TableCell>
      <TableCell className="py-4 text-sm sm:text-base">
        {dayjs(block.time).format('YYYY-MM-DD HH:mm:ss')}
      </TableCell>
    </>
  );
};
