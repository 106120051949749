import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Card, DataCardHeader, Header, logger } from '../../../shared';
import {
  EmptyVotesBar,
  IProposalContent,
  IProposalResult,
  VotesBar,
  proposalStore
} from '../../../entities/proposal';
import { PageHeader } from '../../../widgets/page-header';
import clsx from 'clsx';
import { blockStore } from '../../../entities/block';
import { isGrace, isVoting } from '../../../entities/proposal/lib';
import { voteStore } from '../../../entities/vote';
import { ProposalVotes } from '../../../widgets/proposal-votes';

export const ProposalPage = () => {
  const { id } = useParams();
  const { epoch, epochLoading } = blockStore();

  const { proposalData, getProposalData, proposalDataLoading } =
    proposalStore();

  const { getProposalVotes, proposalVotes, proposalVotesLoading } = voteStore();

  const [result, setResult] = useState<IProposalResult | undefined>(
    proposalData && proposalData.result && JSON.parse(proposalData.result)
  );
  const [content, setContent] = useState<IProposalContent | undefined>(
    proposalData && proposalData.content && JSON.parse(proposalData.content)
  );

  useEffect(() => {
    getProposalData(id as string);
    getProposalVotes(id as string);
  }, []);

  useEffect(() => {
    if (proposalData) {
      proposalData.result
        ? setResult(JSON.parse(proposalData.result))
        : setResult(undefined);
      proposalData.content
        ? setContent(JSON.parse(proposalData.content))
        : setContent(undefined);
    }
  }, [proposalData]);

  return (
    <div>
      <PageHeader>Proposal #{id as string} Details</PageHeader>
      <Card
        className={clsx('mb-4 xl:mb-6', proposalDataLoading && 'animate-pulse')}
      >
        <div className="flex items-center justify-between mb-8 lg:mb-16">
          <div className="font-bold lg:text-2xl uppercase text-lg md:text-xl">
            {proposalDataLoading ? 'XXXX XXXX' : content && content?.title}
          </div>
          {(proposalDataLoading || epochLoading) && (
            <div className="text-[10px] md:text-sm shrink-0 bg-neutral-400 animate-pulse text-white px-2 md:px-4 py-1 rounded-full w-20 md:w-24 uppercase text-center">
              Result
            </div>
          )}
          {proposalData &&
            epoch &&
            isVoting(
              Number(epoch?.latestCommited),
              Number(proposalData?.start_epoch),
              Number(proposalData?.end_epoch)
            ) && (
              <div className="text-[10px] md:text-sm shrink-0 px-2 md:px-4 py-1 bg-black text-primary uppercase rounded-full">
                Voting period
              </div>
            )}
          {proposalData &&
            epoch &&
            isGrace(
              Number(epoch?.latestCommited),
              Number(proposalData?.end_epoch),
              Number(proposalData?.grace_epoch)
            ) && (
              <div className="text-[10px] md:text-sm shrink-0 px-2 md:px-4 py-1 bg-black text-primary uppercase rounded-full">
                Grace period
              </div>
            )}
          {epoch &&
            proposalData &&
            Number(epoch.latestCommited) >= Number(proposalData.grace_epoch) &&
            result && (
              <div
                className={clsx(
                  'text-[10px] md:text-sm shrink-0 px-2 md:px-4 py-1 uppercase rounded-full',
                  result.outcome === 'rejected' && 'bg-energy-red text-white',
                  result.outcome === 'passed' && 'bg-energy-green text-white'
                )}
              >
                {result.outcome}
              </div>
            )}
          {epoch &&
            proposalData &&
            Number(epoch.latestCommited) < Number(proposalData.start_epoch) && (
              <div className="text-[10px] md:text-sm shrink-0 bg-black text-white px-2 md:px-4 py-1 rounded-full w-20 md:w-24 uppercase text-center">
                Pending
              </div>
            )}
        </div>
        <div className="flex items-center w-full mb-6">
          <div className="w-1/2 md:w-1/3">
            <div className="font-semibold text-lg uppercase">Type</div>
            <div className="font-light text-sm">
              {proposalDataLoading
                ? 'XXXX XXXX'
                : proposalData && proposalData.type}
            </div>
          </div>
          <div>
            <div className="font-semibold text-lg uppercase">Total</div>
            <div className="font-light text-sm">
              {proposalDataLoading
                ? 'XXXX XXXX'
                : result
                ? (result.yay + result.nay + result.abstain).toFixed(2)
                : '-'}
            </div>
          </div>
        </div>

        <div className="mb-6">
          {proposalDataLoading ? (
            <EmptyVotesBar />
          ) : result ? (
            <VotesBar
              yay={result.yay}
              nay={result.nay}
              abstain={result.abstain}
            />
          ) : (
            <EmptyVotesBar />
          )}
        </div>

        <div className="flex sm:items-center flex-col sm:flex-row items-start">
          <div className="flex items-start sm:w-1/3 mb-2 sm:mb-0">
            <div className="mt-1 w-4 h-4 lg:w-5 lg:h-5 bg-energy-green rounded-full shrink-0 mr-2 lg:mr-6" />
            <div className="flex items-center gap-2 sm:block">
              <div className="text-base lg:text-lg font-thin uppercase">
                Yes
              </div>
              <div className="text-sm md:text-base font-thin uppercase flex items-center">
                <div>
                  {proposalDataLoading
                    ? 'XX.XX%'
                    : result
                    ? (
                        (result.yay * 100) /
                        (result.yay + result.nay + result.abstain)
                      ).toFixed(2) + '%'
                    : '-'}
                </div>
                {!proposalDataLoading && result && (
                  <span className="text-xs sm:text-sm md:text-base ml-1">
                    ({result.yay.toFixed(0)})
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-start sm:w-1/3 mb-2 sm:mb-0">
            <div className="mt-1 w-4 h-4 lg:w-5 lg:h-5 bg-energy-red rounded-full shrink-0 mr-2 lg:mr-6" />
            <div className="flex items-center gap-2 sm:block">
              <div className="text-base lg:text-lg font-thin uppercase">No</div>
              <div className="text-sm md:text-base font-thin uppercase flex items-center">
                <div>
                  {proposalDataLoading
                    ? 'XX.XX%'
                    : result
                    ? (
                        (result.nay * 100) /
                        (result.yay + result.nay + result.abstain)
                      ).toFixed(2) + '%'
                    : '-'}
                </div>
                {!proposalDataLoading && result && (
                  <span className="text-xs sm:text-sm md:text-base ml-1">
                    ({result.nay.toFixed()})
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-start sm:w-1/3">
            <div className="mt-1 w-4 h-4 lg:w-5 lg:h-5 bg-neutral-300 rounded-full shrink-0 mr-2 lg:mr-6" />
            <div className="flex items-center gap-2 sm:block">
              <div className="text-base lg:text-lg font-thin uppercase">
                Abstain
              </div>
              <div className="text-sm md:text-base font-thin uppercase flex items-center">
                <div>
                  {proposalDataLoading
                    ? 'XX.XX%'
                    : result
                    ? (
                        (result.abstain * 100) /
                        (result.yay + result.nay + result.abstain)
                      ).toFixed(2) + '%'
                    : '-'}
                </div>
                {!proposalDataLoading && result && (
                  <span className="text-xs sm:text-sm md:text-base ml-1">
                    ({result.abstain.toFixed()})
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>

      <div className="flex flex-col md:flex-row items-center mb-10 gap-4 xl:gap-6">
        <Card className="w-full md:w-1/2 lg:w-4/12 self-stretch">
          <div
            className={clsx(
              'space-y-4 md:space-y-6',
              proposalDataLoading && 'animate-pulse'
            )}
          >
            <div>
              <div className="font-bold lg:text-2xl uppercase mb-1 md:mb-2 text-lg md:text-xl">
                Start epoch
              </div>
              <div>
                {proposalDataLoading
                  ? 'XXX'
                  : proposalData && proposalData.start_epoch}
              </div>
            </div>
            <div>
              <div className="font-bold lg:text-2xl uppercase mb-1 md:mb-2">
                End epoch
              </div>
              <div>
                {proposalDataLoading
                  ? 'XXX'
                  : proposalData && proposalData.end_epoch}
              </div>
            </div>
            <div>
              <div className="font-bold lg:text-2xl uppercase mb-1 md:mb-2 text-lg md:text-xl">
                Grace epoch
              </div>
              <div>
                {proposalDataLoading
                  ? 'XXX'
                  : proposalData && proposalData.grace_epoch}
              </div>
            </div>
          </div>
        </Card>

        <Card
          className={clsx(
            'w-full md:w-1/2 lg:w-8/12 self-stretch',
            proposalDataLoading && 'animate-pulse'
          )}
        >
          <div className="overflow-hidden h-full">
            <div className="font-bold lg:text-2xl uppercase mb-2 md:mb-6 text-lg md:text-xl">
              Description
            </div>
            <div
            // className={clsx(
            //   proposalDataLoading && 'h-5/6 w-full bg-neutral-200'
            // )}
            >
              {!proposalDataLoading && content && content.details}
            </div>
          </div>
        </Card>
      </div>

      {/* Plain */}
      <div className="mb-10">
        <Header className="mb-4 xl:mb-6">Plain Data</Header>
        <Card className={clsx(proposalDataLoading && 'h-32')}>
          <div
            className={clsx(
              'overflow-x-auto',
              proposalDataLoading &&
                'bg-neutral-300 h-full w-full animate-pulse rounded-xl'
            )}
          >
            {proposalDataLoading
              ? ''
              : proposalData &&
                proposalData.plain
                  .split('\n')
                  .map((d) => <div key={d}>{d}</div>)}
          </div>
        </Card>
      </div>

      {epoch &&
        proposalData &&
        Number(epoch.latestCommited) >= Number(proposalData.start_epoch) && (
          <div className="mb-10">
            <Header className="mb-4 xl:mb-6">Votes</Header>
            <ProposalVotes />
          </div>
        )}
    </div>
  );
};
