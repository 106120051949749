const getEnvVar = (key: string) => {
  if (import.meta.env[key] === undefined) {
    throw new Error(`Env variable ${key} is required`);
  }
  return import.meta.env[key] || '';
};

export const ENVIRONMENT = getEnvVar('VITE_ENVIRONMENT');
export const RPC_URL = getEnvVar('VITE_RPC_URL');
export const WS_URL = getEnvVar('VITE_WS_URL');
export const VALOPERS_URL = getEnvVar('VITE_VALOPERS_URL');
export const INDEXER_URL = getEnvVar('VITE_INDEXER_URL');
export const PROPOSALS_URL = getEnvVar('VITE_PROPOSALS_URL');

export const VAL_LOGO_FALLBACK =
  'https://backend.nodes.guru/assets/60e763d5-4248-4eb5-a262-93ecb31fe6a3';

export const BLOCKSNUM = 100;
export const SUPPLY = 1000000000;
