import { useEffect, useState } from 'react';
import { IBlock, blockStore } from '../../../entities/block';
import { IValidator, validatorsStore } from '../../../entities/validator';
import { logger } from '../../../shared';

export type UptimeBlocks = {
  height: number;
  status: number;
};

export type UptimeValidator = {
  address: string;
  moniker: string;
  blocks: UptimeBlocks[];
};

export const initialShrinkedBlocks: UptimeBlocks[] = [];

for (let i = 0; i < 50; i++) {
  initialShrinkedBlocks.push({ height: -1, status: -1 });
}

export const useValidatorBlocksUptime = () => {
  const calculateUptimeBlocks = (
    sortedBlocks: IBlock[],
    activeValidators: IValidator[]
  ) => {
    if (activeValidators.length === 0) return;
    if (sortedBlocks.length === 0 || sortedBlocks[0] === undefined) return;

    const sorted = [...sortedBlocks];
    sorted.length = 50;

    const fullUptimeBlocks = activeValidators.map((v) => {
      return {
        address: v.address,
        moniker: v?.moniker || '',
        blocks: sorted.map((b) => {
          if (b.proposer_address === v.tendermint_key) {
            return { height: b.block_height, status: 2 };
          } else {
            const filtered = b.signatures.filter(
              (s) => s.validator_address === v.tendermint_key
            );
            if (filtered.length > 0) {
              return { height: b.block_height, status: 1 };
            } else {
              return { height: b.block_height, status: 0 };
            }
          }
        })
      };
    });

    return fullUptimeBlocks;
  };

  return { calculateUptimeBlocks };
};
