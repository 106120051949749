import { IDelegations, VALOPERS_URL, httpService } from '../../../shared';

import { IAccDataResponse, IAccTransactions } from '../model';

export const accountApi = {
  getAccountData: async (address: string): Promise<IAccDataResponse> => {
    const data: IAccDataResponse = await httpService.get(
      VALOPERS_URL + '/getBalance/' + address
    );

    return data;
  },
  getAccTransactions: async (
    address: string,
    page: number
  ): Promise<IAccTransactions> => {
    const data: IAccTransactions = await httpService.get(
      VALOPERS_URL + '/getTxs/' + address + `?page=${page}&pageSize=10`
    );

    return data;
  },
  getDelegations: async (address: string): Promise<IDelegations> => {
    const data = await httpService.get(
      VALOPERS_URL + '/getDelegation/' + address
    );

    return data;
  }
};
