import { FaDiscord, FaGithub, FaTelegram } from 'react-icons/fa';
import { AiOutlineGlobal } from 'react-icons/ai';
import { FaTwitter } from 'react-icons/fa6';
import { IconPropsType } from '../../../shared';

export type TSocialLink = {
  link: string;
  icon: IconPropsType;
};

export const links: TSocialLink[] = [
  {
    link: 'https://namada.net/',
    icon: AiOutlineGlobal
  },
  {
    link: 'https://discord.com/invite/namada',
    icon: FaDiscord
  },
  {
    link: 'https://github.com/anoma/namada',
    icon: FaGithub
  },
  {
    link: 'https://twitter.com/namada',
    icon: FaTwitter
  },
  {
    link: 'https://t.me/namadaprotocol',
    icon: FaTelegram
  }
];
