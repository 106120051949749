import clsx from 'clsx';

export const Logo = ({ isExtended = true }: { isExtended?: boolean }) => {
  return (
    <div className="h-16 items-center lg:mb-6 xl:mb-12 text-right">
      {isExtended ? (
        <svg
          viewBox="0 0 215 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className=""
        >
          <path
            d="M91.6971 27.2769H88.2227V18.8605H83.0442V27.2769H79.5697V15.3975H91.6971V27.2769Z"
            fill="#FFFF00"
          />
          <path
            d="M131.023 15.4194H127.526V27.2769H131.023V15.4194Z"
            fill="#FFFF00"
          />
          <path
            d="M139.167 15.4194H135.67V27.2769H139.167V15.4194Z"
            fill="#FFFF00"
          />
          <path
            d="M135.095 15.4194H131.598V27.2769H135.095V15.4194Z"
            fill="#FFFF00"
          />
          <path
            d="M188.318 21.929H184.821C184.821 20.2413 183.427 18.8824 181.745 18.8824V15.4194C185.375 15.4194 188.318 18.3344 188.318 21.929Z"
            fill="#FFFF00"
          />
          <path
            d="M188.429 26.9701H176.788V15.4194H180.285V23.507H188.429V26.9701Z"
            fill="#FFFF00"
          />
          <path
            d="M205.889 19.6057H202.393V23.0906H205.889V19.6057Z"
            fill="#FFFF00"
          />
          <path
            d="M214.033 19.6057H210.537V23.0906H214.033V19.6057Z"
            fill="#FFFF00"
          />
          <path
            d="M209.961 19.6057H206.465V23.0906H209.961V19.6057Z"
            fill="#FFFF00"
          />
          <path
            d="M209.961 15.4194H206.465V18.9043H209.961V15.4194Z"
            fill="#FFFF00"
          />
          <path
            d="M205.889 23.792H202.393V27.2769H205.889V23.792Z"
            fill="#FFFF00"
          />
          <path
            d="M214.033 23.792H210.537V27.2769H214.033V23.792Z"
            fill="#FFFF00"
          />
          <path
            d="M205.889 15.4194H202.393V18.9043H205.889V15.4194Z"
            fill="#FFFF00"
          />
          <path
            d="M214.033 15.4194H210.537V18.9043H214.033V15.4194Z"
            fill="#FFFF00"
          />
          <path
            d="M163.753 27.1235H160.257V18.707H155.1V27.1235H151.626V15.244H163.753V27.1235Z"
            fill="#FFFF00"
          />
          <path
            d="M159.438 21.1837C159.438 22.1481 158.663 22.9153 157.69 22.9153C156.716 22.9153 155.941 22.1481 155.941 21.1837C155.941 20.2194 156.716 19.4522 157.69 19.4522C158.663 19.4522 159.438 20.2413 159.438 21.1837Z"
            fill="#FFFF00"
          />
          <path
            d="M109.114 15.4194L105.661 21.3591H112.566L109.114 15.4194Z"
            fill="#FFFF00"
          />
          <path
            d="M105.661 21.3372L102.209 27.2769H109.114L105.661 21.3372Z"
            fill="#FFFF00"
          />
          <path
            d="M112.566 21.3372L109.114 27.2769H116.018L112.566 21.3372Z"
            fill="#FFFF00"
          />
          <path
            d="M80.455 48.2522V38.7618H83.7302L86.6514 44.1974V38.7618H89.3071V48.2522H86.1867L83.1327 42.5098V48.2522H80.455Z"
            fill="#FFFF00"
          />
          <path
            d="M110.928 48.2522L110.419 46.6742H107.122L106.591 48.2522H103.714L107.233 38.7618H110.353L113.894 48.2522H110.928ZM107.808 44.57H109.711L108.76 41.655L107.808 44.57Z"
            fill="#FFFF00"
          />
          <path
            d="M127.924 48.2522V38.7618H131.886L133.523 44.592L135.183 38.7618H138.923V48.2522H136.29V42.1591L134.519 48.2522H132.395L130.624 42.0933V48.2522H127.924Z"
            fill="#FFFF00"
          />
          <path
            d="M159.903 48.2522L159.394 46.6742H156.096L155.565 48.2522H152.688L156.207 38.7618H159.327L162.868 48.2522H159.903ZM156.782 44.57H158.686L157.734 41.655L156.782 44.57Z"
            fill="#FFFF00"
          />
          <path
            d="M178.559 48.2522V38.7618H182.498C185.508 38.7618 187.588 40.4276 187.588 43.3865V43.6057C187.588 46.5865 185.508 48.2303 182.498 48.2303L178.559 48.2522ZM181.347 45.9947H182.343C183.759 45.9947 184.644 45.4029 184.644 43.6057V43.3865C184.644 41.5892 183.737 40.9974 182.365 40.9974H181.347V45.9947Z"
            fill="#FFFF00"
          />
          <path
            d="M209.984 48.2522L209.475 46.6742H206.177L205.646 48.2522H202.769L206.288 38.7618H209.408L212.949 48.2522H209.984ZM206.863 44.57H208.766L207.815 41.655L206.863 44.57Z"
            fill="#FFFF00"
          />
          <path
            d="M44.781 31.7481H32.9634V43.4522H44.781V31.7481Z"
            fill="#FFFF00"
          />
          <path
            d="M21.1237 20.0221C27.6521 20.0221 32.9412 25.2605 32.9412 31.7262H21.1237V20.0221Z"
            fill="#FFFF00"
          />
          <path
            d="M21.1236 31.7481L14.3075 43.4522H27.9619L21.1236 31.7481Z"
            fill="#FFFF00"
          />
          <path
            d="M44.8252 31.7262C48.0885 31.7262 50.734 29.1061 50.734 25.8741C50.734 22.6421 48.0885 20.0221 44.8252 20.0221C41.5618 20.0221 38.9164 22.6421 38.9164 25.8741C38.9164 29.1061 41.5618 31.7262 44.8252 31.7262Z"
            fill="#FFFF00"
          />
          <path
            d="M7.25964 12.9636C12.3393 6.27102 19.7355 1.96119 28.0844 0.773482C36.4509 -0.401058 44.7696 1.73394 51.5094 6.75162C58.2492 11.7693 62.6185 19.1077 63.8177 27.3764C65.017 35.6451 62.848 43.9014 57.7816 50.5765C52.7153 57.2516 45.3057 61.5789 36.9569 62.7666C28.608 63.9543 20.2717 61.8062 13.5319 56.7885C6.79208 51.7708 2.42278 44.4324 1.22355 36.1637C0.0553094 27.8907 2.19331 19.6387 7.25964 12.9636ZM54.6859 48.2718C59.1273 42.4201 61.0163 35.1893 59.962 27.9333C58.9254 20.6906 55.0951 14.247 49.1867 9.84829C43.2783 5.44959 35.9774 3.5787 28.6511 4.62286C21.3248 5.66702 14.8321 9.44303 10.3907 15.2947C5.94938 21.1463 4.06036 28.3772 5.11464 35.6331C6.15123 42.8759 9.98154 49.3195 15.8899 53.7182C21.7983 58.1169 29.0993 59.9878 36.4255 58.9436C43.7518 57.8994 50.2446 54.1234 54.6859 48.2718Z"
            fill="#FFFF00"
          />
        </svg>
      ) : (
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_94_3956)">
            <path
              d="M33.309 24.002H24.3344V32.9767H33.309V24.002Z"
              fill="#FFFF00"
            />
            <path
              d="M15.3551 15.0233C20.3087 15.0233 24.3298 19.045 24.3298 23.998H15.3551V15.0233Z"
              fill="#FFFF00"
            />
            <path
              d="M15.3551 24.002L10.1733 32.9773H20.5368L15.3551 24.002Z"
              fill="#FFFF00"
            />
            <path
              d="M33.3419 23.9951C35.8188 23.9951 37.8267 21.9873 37.8267 19.5104C37.8267 17.0335 35.8188 15.0256 33.3419 15.0256C30.8651 15.0256 28.8572 17.0335 28.8572 19.5104C28.8572 21.9873 30.8651 23.9951 33.3419 23.9951Z"
              fill="#FFFF00"
            />
            <path
              d="M24.0003 48C17.5894 48 11.5627 45.5036 7.02953 40.9705C2.49639 36.4379 0 30.4106 0 24.0003C0 17.59 2.49639 11.5627 7.02953 7.02953C11.5627 2.49639 17.5894 0 24.0003 0C30.4112 0 36.4379 2.49639 40.971 7.02953C45.5042 11.5627 48.0006 17.5894 48.0006 24.0003C48.0006 30.4112 45.5042 36.4379 40.971 40.971C36.4379 45.5042 30.4112 48.0006 24.0003 48.0006V48ZM24.0003 2.9739C18.3843 2.9739 13.1041 5.16098 9.13252 9.13252C5.16098 13.1035 2.9739 18.3837 2.9739 24.0003C2.9739 29.6169 5.16098 34.8965 9.13252 38.8681C13.1041 42.8396 18.3843 45.0267 24.0003 45.0267C29.6163 45.0267 34.8965 42.8396 38.8681 38.8681C42.8396 34.8965 45.0267 29.6163 45.0267 24.0003C45.0267 18.3843 42.8396 13.1041 38.8681 9.13252C34.8965 5.16098 29.6163 2.9739 24.0003 2.9739Z"
              fill="#FFFF00"
            />
          </g>
          <defs>
            <clipPath id="clip0_94_3956">
              <rect width="48" height="48" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
      <span
        className={clsx(
          ' text-white font-light -mt-3',
          isExtended ? 'block' : 'hidden'
        )}
      >
        explorer
      </span>
    </div>
  );
};
