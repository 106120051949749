import { IconPropsType } from '../api';

type BoxIconProps = {
  icon: IconPropsType;
  size: number;
  borderWidth: number;
};

export const BoxIcon = (props: BoxIconProps) => {
  return (
    <div
      style={{ width: `${props.size}px`, height: `${props.size}px` }}
      className="flex items-center justify-center rounded-md relative bg-gradient-to-b from-neutral-500 via-neutral-700 to-black shrink-0"
    >
      <div
        style={{
          margin: `${props.borderWidth}px`,
          width: `calc(100%-${props.borderWidth * 2}px)`,
          height: `calc(100%-${props.borderWidth * 2}px)`
        }}
        className="bg-neutral-800 flex items-center justify-center w-[calc(100%-2px)] h-[calc(100%-2px)] rounded-md "
      >
        <props.icon
          style={{
            width: `${props.size / 2}px`,
            height: `${props.size / 2}px`
          }}
          className="shrink-0"
          aria-hidden="true"
        />
      </div>
    </div>
  );
};
