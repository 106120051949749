import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import {
  LayoutWithLoaderAndError,
  Card,
  logger,
  Header
} from '../../../shared';

import { IValidator, validatorsStore } from '../../../entities/validator';

import { NameLogo } from './NameLogo';
import clsx from 'clsx';
import { VotingPowerBlock } from './VotingPowerBlock';
import { UptimeByBlocks } from '../../../widgets/uptime-blocks';
import { PageHeader } from '../../../widgets/page-header';
import { ValidatorStatus } from '../../../widgets/validator-status';
import { ValidatorContacts } from '../../../widgets/validator-contacts';
import { ValidatorAddress } from '../../../widgets/validator-address';
import { Delegations } from '../../../widgets/delegations';
import { AccVotesTable } from '../../../widgets/list-votes';
import { voteStore } from '../../../entities/vote';

export const ValidatorPage = () => {
  const { address } = useParams();
  const [validator, setValidator] = useState<IValidator>();

  const {
    allValidators,
    totalBonded,
    validatorsLoading,
    findValidatorByAddress,
    validatorsError,
    getValidators,
    getValDelegations,
    valDelegators: valDelegations,
    valDelegatorsLoading: valDelegationsLoading
  } = validatorsStore();

  const { accVotes, getVotes, accVotesLoading } = voteStore();

  const handleGetValidatorData = () => {
    const validator = findValidatorByAddress(address as string);
    setValidator(validator);
  };

  useEffect(() => {
    if (allValidators.length > 0) {
      handleGetValidatorData();
    } else {
      getValidators();
    }
  }, [allValidators, address]);

  useEffect(() => {
    getValDelegations(address as string);
    getVotes(address as string);
  }, []);

  return (
    <LayoutWithLoaderAndError
      isLoading={validatorsLoading}
      error={validatorsError}
    >
      {validator && (
        <>
          <PageHeader>Validator details</PageHeader>
          <div className="w-full flex flex-col md:flex-row gap-4 xl:gap-6 mb-4 xl:mb-6">
            <Card className={clsx('w-full md:w-4/12 lg:w-5/12 flex')}>
              <div className={clsx(!validator.description && 'h-full')}>
                <NameLogo
                  moniker={validator?.moniker || validator.address}
                  logo={validator.logo}
                  descr={validator.description}
                />
              </div>
            </Card>

            <Card className={clsx('w-full md:w-8/12 lg:w-7/12')} color="yellow">
              <ValidatorStatus {...validator} />
            </Card>
          </div>

          <div className="flex flex-col md:flex-row gap-4 xl:gap-6 mb-4 xl:mb-6">
            <Card className="w-full md:w-1/2 lg:w-7/12 xl:w-4/12" color="black">
              <ValidatorContacts {...validator} />
            </Card>
            <Card className="w-full md:w-1/2 lg:w-5/12 xl:w-8/12">
              {validator.amount && totalBonded && (
                <VotingPowerBlock
                  amount={validator.amount}
                  percentage={(validator.amount * 100) / totalBonded}
                />
              )}
            </Card>
          </div>

          <Card className="mb-4 xl:mb-6">
            {validator && <UptimeByBlocks validator={validator} />}
          </Card>

          <Card className="mb-10">
            <ValidatorAddress {...validator} />
          </Card>

          <div className="mb-10">
            <Header className="mb-4 xl:mb-6">Delegators</Header>
            <Delegations
              delegations={valDelegations}
              isLoading={valDelegationsLoading}
              isDelegator={true}
            />
          </div>

          {/* Votes */}
          <div className="mb-10">
            <Header className="mb-4 xl:mb-6">Votes</Header>
            <AccVotesTable votes={accVotes} isLoading={accVotesLoading} />
          </div>
        </>
      )}
    </LayoutWithLoaderAndError>
  );
};
