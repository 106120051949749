import clsx from 'clsx';
import { Card, DataCardHeader, Loader } from '../../../shared';

import { Line } from 'rc-progress';
import { validatorsStore } from '../../../entities/validator';

export const ActiveValidators = () => {
  const {
    activeValidators,
    validatorsLoading,
    validatorsError,
    allValidators,
    activeValspercent
  } = validatorsStore();

  return (
    <div className="flex flex-col justify-between h-full">
      <DataCardHeader mb="mb-2 sm:mb-0">Active validators</DataCardHeader>
      <div
        className={clsx(
          'sm:text-lg xl:text-xl font-light',
          validatorsLoading && 'flex justify-center items-center pt-3'
        )}
      >
        {validatorsLoading ? (
          <Loader />
        ) : validatorsError ? (
          'Fetch error'
        ) : (
          <>
            <div className="flex justify-between mb-2 sm:mb-4 gap-2">
              <span>{`${activeValidators.length} out of ${allValidators.length}`}</span>{' '}
              <span>{`${activeValspercent.toFixed(2)}%`}</span>
            </div>
            <Line
              percent={activeValspercent}
              strokeWidth={1}
              strokeColor="#000"
              trailColor="yellow"
              trailWidth={100}
              strokeLinecap="round"
              className="h-3 w-full rounded-full"
            />
          </>
        )}
      </div>
    </div>
  );
};
