import {
  IGovParams,
  IPosParams,
  IProtParams,
  IPubGoodsParams
} from '../../../entities/parameter';
import {
  Card,
  DataCardHeader,
  formatNumber,
  removeUnderscores
} from '../../../shared';

type ParametersCardProps = {
  params: IGovParams | IPosParams | IProtParams | IPubGoodsParams;
  title: string;
};

export const ParametersCard = ({ params, title }: ParametersCardProps) => {
  return (
    <div className="self-stretch">
      <DataCardHeader mb="mb-4 xl:mb-6">{title}</DataCardHeader>
      <Card>
        <div className="h-44 sm:h-60 md:h-72 overflow-y-auto pr-4">
          {Object.keys(params).map((p: string) => (
            <div
              key={`${p}-${params[p]}`}
              className="flex items-center justify-between mb-2 sm:mb-5"
            >
              <div className="text-sm md:text-lg lg:text-xl">
                {removeUnderscores(p)}
              </div>
              <div className="text-xs md:text-sm lg:text-base">
                {formatNumber(params[p])}
              </div>
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
};
