export const activeClasses = 'relative active:top-0.5 active:left-px';

export const hideHashPart = (hash: string) => {
  const part1 = hash.slice(0, 6);
  const part2 = hash.slice(-6, hash.length);

  return `${part1}...${part2}`;
};

export const removeUnderscores = (str: string) => {
  return str.replace(/_/g, ' ');
};

export const handleCopyValue = (value: string) => {
  navigator.clipboard.writeText(value);
};

export const createEmptyArray = (length: number) => {
  const arr = [];
  for (let i = 0; i < length; i++) {
    arr.push('-');
  }

  return arr;
};

export function formatNumber(
  number: number | string,
  withDecimals: boolean = false
) {
  let numStr = number;

  if (typeof numStr === 'number') numStr = number.toString();

  const parts = numStr.split('.');

  let integerPart = parts[0];

  if (integerPart.length < 4)
    return Number(numStr).toFixed(withDecimals ? 2 : 0);

  const decimalPart = withDecimals
    ? parts.length > 1
      ? '.' + parts[1].substring(0, 2)
      : '.00'
    : '';

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return integerPart + decimalPart;
}
