import { PropsWithChildren, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { logger } from '../lib';

export const ErrorBoundaryWrapper = ({ children }: PropsWithChildren) => {
  return (
    <ErrorBoundary FallbackComponent={FallbackComponent}>
      {children}
    </ErrorBoundary>
  );
};

const FallbackComponent = ({ error }: { error: Error }) => {
  useEffect(() => {
    logger.error('error', error);
  }, []);
  return <div>An unexpected error occured</div>;
};
