import clsx from 'clsx';
import { PropsWithChildren } from 'react';

type HeaderProps = {
  className?: string;
};

export const Header = ({
  children,
  className
}: PropsWithChildren<HeaderProps>) => {
  return (
    <h1
      className={clsx(
        'text-xl font-bold xl:leading-10 md:text-2xl xl:text-[35px] uppercase tracking-wider',
        className
      )}
    >
      {children}
    </h1>
  );
};
