import { validatorsStore } from '../../../entities/validator';
import { useTableSearch } from '../../../features/table-search';
import { CardSearch } from '../../../shared';
import { ProposalVote } from './ProposalVote';

type Props = {
  votes: string[];
};

export const ProposalVotesTab = ({ votes }: Props) => {
  const { findValidatorByAddress } = validatorsStore();

  const { searchInput, handleChangeSearchInput, filteredData } = useTableSearch(
    votes,
    (v) => {
      if (typeof v === 'string') {
        const moniker = findValidatorByAddress(v)?.moniker;

        if (moniker) {
          return (
            v.includes(searchInput.toLowerCase()) ||
            moniker.toLowerCase().includes(searchInput.toLowerCase())
          );
        } else {
          return v.includes(searchInput.toLowerCase());
        }
      } else return false;
    }
  );

  return (
    <>
      <CardSearch
        searchInput={searchInput}
        onChange={handleChangeSearchInput}
        placeholder="address"
      />
      <div className="h-80 overflow-y-scroll ">
        <div className="md:grid md:grid-cols-2 lg:grid-cols-3 gap-2 w-full">
          {filteredData &&
            filteredData.map((address) => (
              <ProposalVote
                key={address as string}
                address={address as string}
              />
            ))}
        </div>
      </div>
    </>
  );
};
