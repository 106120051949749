import axios, { AxiosError } from 'axios';

const httpService = {
  get: async (url: string) => {
    const { data } = await axios.get(url);

    return data;
  },
  post: async (url: string, payload: any) => {
    const { data } = await axios.post(url, payload);

    return data;
  }
};

export { httpService };
