import clsx from 'clsx';
import { logger } from '../../../shared';
import { useEffect } from 'react';

type NumbersCellProps = {
  title: string;
  value: string;
  isJailed?: boolean;
  isLoading: boolean;
};

export const NumbersCell = ({
  title,
  value,
  isJailed,
  isLoading
}: NumbersCellProps) => {
  return (
    <div
      className={clsx(
        'flex flex-col justify-center items-center text-center font-medium transition-all',
        isLoading && 'animate-pulse'
      )}
    >
      <div
        className={clsx(
          'text-2xl md:text-3xl lg:text-xl xl:text-3xl w-28',
          isJailed && 'text-red-500',
          isLoading && 'blur-md'
        )}
      >
        {value}
      </div>
      <div className="md:text-lg lg:text-sm xl:text-lg">{title}</div>
    </div>
  );
};
