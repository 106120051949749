import { useState } from 'react';
import { createEmptyArray } from '../..';

type SkeletonRowProps = {
  cellsCount: number;
};

export const SkeletonRow = ({ cellsCount }: SkeletonRowProps) => {
  const [cellsArr, _] = useState(createEmptyArray(cellsCount));

  return (
    <>
      {cellsArr.map((c, ind) => {
        return (
          <td
            key={`cell-${ind}`}
            className="py-4 px-4 text-center animate-pulse leading-6 text-transparent"
          >
            -
          </td>
        );
      })}
    </>
  );
};
