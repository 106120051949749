import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa6';

type ValidatorLogoProps = {
  logo: string | undefined;
  isMainLogo?: boolean;
};

const ValidatorLogo = ({ logo, isMainLogo }: ValidatorLogoProps) => {
  const [logoImg, setLogoImg] = useState<string | undefined>(logo);

  const handleError = () => {
    setLogoImg(undefined);
  };

  useEffect(() => {
    setLogoImg(logo);
  }, [logo]);

  return (
    <div
      className={clsx(
        'flex-shrink-0 overflow-hidden  flex items-center justify-center rounded-full',
        isMainLogo ? 'w-16 h-16' : 'w-6 h-6',
        isMainLogo && logoImg && 'rounded-md',
        !logoImg && 'border-black bg-black border-4'
      )}
    >
      {logoImg ? (
        <img
          className="w-full h-full object-contain"
          src={logoImg}
          onError={handleError}
        />
      ) : (
        <FaUser
          className={clsx(
            'text-white ',
            isMainLogo ? 'text-5xl mt-3' : 'text-xl mt-1'
          )}
        />
      )}
    </div>
  );
};

export default ValidatorLogo;
