import clsx from 'clsx';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { BoxIcon } from '../../../shared/ui/BoxIcon';
import { IconPropsType } from '../../../shared/api';

type NavItemProps = {
  title: string;
  path: string;
  icon: IconPropsType;
  element: React.JSX.Element;
  isExtended?: boolean;
  onClose?: () => void;
};

export const NavItem = ({
  isExtended = true,
  onClose,
  ...item
}: NavItemProps) => {
  return (
    <NavLink
      to={item.path}
      onClick={onClose}
      className={({ isActive }) =>
        clsx(
          isActive ? ' text-primary' : 'text-white hover:text-primary ',
          isExtended && (isActive ? 'bg-neutral-600' : 'hover:bg-neutral-700'),
          isExtended && 'pr-4',
          'group flex gap-x-3.5 rounded-md text-lg xl:text-xl leading-6 font-light items-center '
        )
      }
    >
      <BoxIcon size={48} borderWidth={1} icon={item.icon} />
      {isExtended && item.title}
    </NavLink>
  );
};
