import { useState } from 'react';
import { ValidatorMoniker, validatorsStore } from '../../../entities/validator';
import {
  ACCOUNT_ROUTE,
  LinkWrap,
  activeClasses,
  handleCopyValue,
  hideHashPart
} from '../../../shared';

export const ProposalVote = ({ address }: { address: string }) => {
  const { findValidatorByAddress } = validatorsStore();
  const [validator, _] = useState(findValidatorByAddress(address));

  return (
    <div className="flex items-center space-x-2 mb-2 truncate">
      {validator ? (
        <ValidatorMoniker validator={validator} truncate={true} className="" />
      ) : (
        <LinkWrap to={ACCOUNT_ROUTE + '/' + address}>
          {hideHashPart(address)}
        </LinkWrap>
      )}
    </div>
  );
};
