import { useEffect } from 'react';
import { transactionStore } from '../../../entities/transaction';
import { LayoutWithLoaderAndError } from '../../../shared';
import { blockStore } from '../../../entities/block';
import { TransactionsTable } from '../../../features/list-transactions';
import { PageHeader } from '../../../widgets/page-header';
import { useTabs, TabSwitcher } from '../../../features/tab-switcher';
import { TransactionsTableWithSearch } from '../../../features/list-transactions/ui/TransactionsTableWithSearch';

const tabs = ['Wrapper', 'Decrypted'];

export const Transactions = () => {
  const {
    wrapperTxs,
    decryptedTxs,
    getTransactions,
    transactionsLoading,
    transactionsError
  } = transactionStore();
  const { latestBlockHeight } = blockStore();
  const { currentTab, handleChangeTab } = useTabs(tabs);

  useEffect(() => {
    if (latestBlockHeight) {
      getTransactions(latestBlockHeight);
    }
  }, [latestBlockHeight]);

  return (
    <LayoutWithLoaderAndError
      isLoading={transactionsLoading}
      error={transactionsError}
    >
      {wrapperTxs && decryptedTxs && (
        <div>
          <PageHeader isHiding>Transactions</PageHeader>
          <div className="mb-4">
            <TabSwitcher
              onChange={handleChangeTab}
              currentTab={currentTab}
              tabs={tabs}
            />
          </div>

          {currentTab === 'Wrapper' && (
            <TransactionsTableWithSearch transactions={wrapperTxs} />
          )}
          {currentTab === 'Decrypted' && (
            <TransactionsTableWithSearch transactions={decryptedTxs} />
          )}
        </div>
      )}
    </LayoutWithLoaderAndError>
  );
};
