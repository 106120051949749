import { useEffect, useState } from 'react';
import { IBalances } from '../../../entities/account';
import {
  TableRow,
  TableWithHeaders,
  SkeletonRow,
  Nothing
} from '../../../shared';
import { TokenRow } from './TokenRow';

const headers = [
  { name: 'Name', align: 'text-left' },
  { name: 'Amount', align: 'text-left' },
  { name: 'Total Value', align: 'text-left' }
];

type TokensTableProps = {
  balances: IBalances | null;
  isLoading: boolean;
};

export const TokensTable = ({ balances, isLoading }: TokensTableProps) => {
  const [filteredBalances, setFilteredBalances] = useState(balances);

  useEffect(() => {
    if (filteredBalances && filteredBalances.naan) {
      delete filteredBalances.naan;
      setFilteredBalances(filteredBalances);
    }
  }, [balances]);

  return (
    <>
      <TableWithHeaders headers={headers} isLoading={isLoading}>
        {isLoading ? (
          <TableRow>
            <SkeletonRow cellsCount={3} />
          </TableRow>
        ) : (
          filteredBalances &&
          Object.keys(filteredBalances).length > 0 &&
          Object.keys(filteredBalances).map((t) => {
            return (
              <TableRow key={t}>
                <TokenRow token={t} amount={filteredBalances[t] as string} />
              </TableRow>
            );
          })
        )}
      </TableWithHeaders>
      {!isLoading &&
        (!filteredBalances ||
          (filteredBalances && Object.keys(filteredBalances).length === 0 && (
            <Nothing />
          )))}
    </>
  );
};
