import { Vortex } from 'react-loader-spinner';

type LoaderProps = {
  size?: number;
};

export const Loader = ({ size = 25 }: LoaderProps) => {
  return (
    <Vortex
      visible
      height={size}
      width={size}
      colors={['black', 'black', 'black', 'black', 'black', 'black']}
      ariaLabel="tail-spin-loading"
    />
  );
};
