import clsx from 'clsx';
import { IoIosArrowDropleft, IoIosArrowDropright } from 'react-icons/io';
import { activeClasses, logger } from '../../../shared';
import { useEffect } from 'react';

type PaginationProps = {
  nextDisabled: boolean;
  prevDisabled: boolean;
  totalPages: number;
  currentPage: number;
  handleGetNextPage: () => void;
  handleGetPrevPage: () => void;
  handleChangePage: (page: number) => void;
};

export const Pagination = ({
  nextDisabled,
  prevDisabled,
  totalPages,
  currentPage,
  handleGetNextPage,
  handleGetPrevPage,
  handleChangePage
}: PaginationProps) => {
  return (
    <div className="flex items-center justify-between mt-4 sm:mt-2 text-sm sm:text-base">
      <button disabled={prevDisabled} onClick={handleGetPrevPage}>
        <IoIosArrowDropleft
          className={clsx(
            'text-2xl sm:text-4xl',
            prevDisabled ? 'text-neutral-300' : 'text-black',
            !prevDisabled && activeClasses
          )}
        />
      </button>

      <div className="flex items-center justify-center gap-6">
        {currentPage !== 1 && (
          <button onClick={() => handleChangePage(1)}>1</button>
        )}
        {currentPage > 3 && <div>...</div>}
        {currentPage > 3 && (
          <button onClick={() => handleChangePage(currentPage - 1)}>
            {currentPage - 1}
          </button>
        )}
        {currentPage === 3 && (
          <button onClick={() => handleChangePage(2)}>{2}</button>
        )}

        <div className="text-xl sm:text-2xl p-1">{currentPage}</div>

        {currentPage < totalPages - 2 && (
          <>
            <button onClick={() => handleChangePage(currentPage + 1)}>
              {currentPage + 1}
            </button>
          </>
        )}

        {currentPage === totalPages - 2 && (
          <button onClick={() => handleChangePage(totalPages - 1)}>
            {totalPages - 1}
          </button>
        )}
        {currentPage < totalPages - 2 && <div>...</div>}
        {currentPage !== totalPages && (
          <button onClick={() => handleChangePage(totalPages)}>
            {totalPages}
          </button>
        )}
      </div>

      <button disabled={nextDisabled} onClick={handleGetNextPage}>
        <IoIosArrowDropright
          className={clsx(
            'text-2xl sm:text-4xl',
            nextDisabled ? 'text-neutral-300' : 'text-black',
            !nextDisabled && activeClasses
          )}
        />
      </button>
    </div>
  );
};
