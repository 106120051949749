import clsx from 'clsx';
import { blockStore } from '../../../entities/block';
import { DataCardHeader, Loader } from '../../../shared';
import { Line } from 'rc-progress';

export const Epoch = () => {
  const { epoch, epochLoading, epochError } = blockStore();

  return (
    <div className="flex flex-col justify-between h-full">
      <DataCardHeader mb="mb-2 sm:mb-0">Epoch</DataCardHeader>
      <div
        className={clsx(
          'sm:text-lg xl:text-xl font-light tracking-wide',
          epochLoading && 'flex justify-center items-center pt-3'
        )}
      >
        {epochLoading ? (
          <Loader />
        ) : epochError ? (
          'Fetch error'
        ) : (
          epoch && (
            <>
              <div className="flex justify-between mb-2 sm:mb-4 gap-2">
                <span>{epoch.latestCommited}</span>{' '}
                <span>{Number(epoch.latestCommited) + 1}</span>
              </div>
              <Line
                percent={Number(epoch.progress) * 100}
                strokeWidth={1}
                strokeColor="#000"
                trailColor="yellow"
                trailWidth={100}
                strokeLinecap="round"
                className="h-3 w-full rounded-full"
              />
            </>
          )
        )}
      </div>
    </div>
  );
};
