import {
  validatorsStore,
  ValidatorMoniker,
  IValidator
} from '../../../entities/validator';
import { blockStore } from '../../../entities/block';
import { Loader, TableCell, formatNumber } from '../../../shared';

type ValidatorRowProps = IValidator & { index: number };

export const ValidatorRow = (validator: ValidatorRowProps) => {
  const { totalBonded } = validatorsStore();
  const { blocksLoading, uptime } = blockStore();

  return (
    <>
      <TableCell className="pr-4 w-10">{validator.index}</TableCell>

      <TableCell className="pr-4">
        {validator && <ValidatorMoniker validator={validator} truncate />}
      </TableCell>

      <TableCell className="pr-4 whitespace-nowrap">
        {validator.amount && formatNumber(validator.amount)}
      </TableCell>
      <TableCell className="pr-4">
        {validator.amount &&
          totalBonded &&
          ((validator.amount / totalBonded) * 100).toFixed(2)}
      </TableCell>
      <TableCell>
        {validator.commission_rate
          ? `${(validator.commission_rate * 100).toFixed()}%`
          : '0%'}
      </TableCell>
      <TableCell>
        {blocksLoading ? (
          <div className="flex justify-center">
            <Loader />
          </div>
        ) : uptime && uptime[validator.tendermint_key] ? (
          `${(uptime[validator.tendermint_key] * 100).toFixed()}%`
        ) : (
          '0%'
        )}
      </TableCell>
    </>
  );
};
