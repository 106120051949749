import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { Header } from '../../../shared';
import { SearchBar } from '../../../features/search';
import { Social } from '../../../features/social';

type PageHeaderProps = {
  isHiding?: boolean;
  noHeader?: boolean;
};

export const PageHeader = ({
  children,
  isHiding,
  noHeader
}: PropsWithChildren<PageHeaderProps>) => {
  return (
    <div
      className={clsx(
        'flex w-full flex-col sm:flex-row sm:items-center gap-4 sm:gap-0 md:justify-between lg:justify-between',
        noHeader ? 'mb-6' : 'mb-4 xl:mb-10'
      )}
    >
      <div
        className={clsx(
          'order-2 lg:order-1 pr-4 sm:w-1/2 lg:w-max',
          isHiding && 'hidden lg:block'
        )}
      >
        {!noHeader && <Header>{children}</Header>}
      </div>
      <div className="flex items-center gap-4 order-1 sm:order-2 lg:order-3 w-full lg:w-min justify-self-end h-9 2xl:h-10">
        <div className="hidden lg:block">
          <Social />
        </div>
        <SearchBar
          className={clsx(
            'h-full w-full lg:w-max',
            isHiding ? 'md:w-1/2' : 'md:w-full'
          )}
        />
      </div>
    </div>
  );
};
