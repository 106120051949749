export const NamadaLogo = () => {
  return (
    <svg
      width="124"
      height="124"
      viewBox="0 0 124 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="block mx-auto"
    >
      <g clipPath="url(#clip0_288_3184)">
        <path
          d="M86.0484 62.0059H62.8638V85.1905H86.0484V62.0059Z"
          fill="#FFFF00"
        />
        <path
          d="M39.6672 38.8105C52.4641 38.8105 62.8519 49.1998 62.8519 61.9952H39.6672V38.8105Z"
          fill="#FFFF00"
        />
        <path
          d="M39.6672 62.0059L26.2811 85.192H53.0533L39.6672 62.0059Z"
          fill="#FFFF00"
        />
        <path
          d="M86.1332 61.9876C92.5318 61.9876 97.7188 56.8006 97.7188 50.402C97.7188 44.0035 92.5318 38.8164 86.1332 38.8164C79.7347 38.8164 74.5476 44.0035 74.5476 50.402C74.5476 56.8006 79.7347 61.9876 86.1332 61.9876Z"
          fill="#FFFF00"
        />
        <path
          d="M62.0007 124C45.4392 124 29.8702 117.551 18.1596 105.84C6.44902 94.1313 0 78.5608 0 62.0007C0 45.4407 6.44902 29.8702 18.1596 18.1596C29.8702 6.44902 45.4392 0 62.0007 0C78.5623 0 94.1313 6.44902 105.842 18.1596C117.552 29.8702 124.001 45.4392 124.001 62.0007C124.001 78.5623 117.552 94.1313 105.842 105.842C94.1313 117.552 78.5623 124.001 62.0007 124.001V124ZM62.0007 7.68257C47.4927 7.68257 33.8521 13.3325 23.5923 23.5923C13.3325 33.8506 7.68257 47.4912 7.68257 62.0007C7.68257 76.5103 13.3325 90.1494 23.5923 100.409C33.8521 110.669 47.4927 116.319 62.0007 116.319C76.5088 116.319 90.1494 110.669 100.409 100.409C110.669 90.1494 116.319 76.5088 116.319 62.0007C116.319 47.4927 110.669 33.8521 100.409 23.5923C90.1494 13.3325 76.5088 7.68257 62.0007 7.68257Z"
          fill="#FFFF00"
        />
      </g>
      <defs>
        <clipPath id="clip0_288_3184">
          <rect width="124" height="124" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
