import { useEffect } from 'react';
import { TTransaction } from '../../../entities/transaction';
import {
  ITransaction,
  TableRow,
  TableWithHeaders,
  createEmptyArray,
  SkeletonRow,
  Nothing
} from '../../../shared';
import { TransactionRow } from './TransactionRow';

type TransactionsProps = {
  transactions: TTransaction[] | ITransaction[];
  isLoading?: boolean;
};

const headers = [
  { name: 'Block', align: 'text-left' },
  { name: 'Type', align: 'text-left' },
  { name: 'Tx-hash', align: 'text-left' }
];

const placeholders = createEmptyArray(10);

export const TransactionsTable = ({
  transactions,
  isLoading
}: TransactionsProps) => {
  return (
    <>
      <TableWithHeaders headers={headers} isLoading={isLoading}>
        {isLoading
          ? placeholders.map((p, ind) => (
              <TableRow key={`${p}-${ind}`}>
                <SkeletonRow cellsCount={3} />
              </TableRow>
            ))
          : transactions &&
            transactions.length > 0 &&
            transactions.map((tx, ind) => (
              <TableRow key={'hash_id' in tx ? tx.hash_id : tx.hash}>
                <TransactionRow {...tx} />
              </TableRow>
            ))}
      </TableWithHeaders>
      {!isLoading &&
        (!transactions ||
          (transactions && transactions.length === 0 && <Nothing />))}
    </>
  );
};

export default TransactionsTable;
