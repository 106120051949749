import { BLOCKSNUM, Card, DataCardHeader, logger } from '../../../shared';
import { blockStore } from '../../../entities/block';
import { IValidator } from '../../../entities/validator';
import {
  BlockByUptime,
  blocksMock,
  getUptimeByBlocks
} from '../model/uptimeBlocksModel';
import { useEffect, useState } from 'react';
import clsx from 'clsx';

type UptimeByBlocksProps = {
  validator: IValidator;
};

export const UptimeByBlocks = ({ validator }: UptimeByBlocksProps) => {
  const { blocks, sortedBlocks, blocksLoading } = blockStore();
  const [blocksByUptime, setBlocksByUptime] =
    useState<BlockByUptime[]>(blocksMock);

  useEffect(() => {
    if (blocks.length > 0) {
      setBlocksByUptime(getUptimeByBlocks(sortedBlocks, validator));
    }
  }, [blocks, validator]);

  return (
    <>
      <DataCardHeader mb="mb-4 xl:mb-6">Uptime by blocks</DataCardHeader>
      <div className="flex flex-wrap gap-1 mb-3">
        {blocksByUptime.map((b, ind) => (
          <a
            href={b.status === 'loading' ? '#' : `/blocks/${b.height}`}
            key={`block-${ind}`}
            title={`${b.height}-${b.status}`}
            className={clsx(
              'block w-3 h-3 rounded',
              b.status === 'active' && 'bg-energy-green',
              b.status === 'inactive' && 'bg-red-500',
              b.status === 'proposer' && 'bg-green-600',
              b.status === 'loading' &&
                blocksLoading &&
                'bg-black animate-pulse cursor-default'
            )}
          />
        ))}
      </div>
      <div className="flex flex-wrap items-center gap-2 md:gap-4 text-xs">
        <div className="flex items-center">
          <div className="w-3 h-3 bg-green-600 rounded mr-1"></div>
          <div> proposer</div>
        </div>
        <div className="flex items-center">
          <div className="w-3 h-3 bg-energy-green rounded mr-1"></div>
          <div> active</div>
        </div>
        <div className="flex items-center">
          <div className="w-3 h-3 bg-red-500 rounded mr-1"></div>
          <div> inactive</div>
        </div>
      </div>
    </>
  );
};
