import { useEffect } from 'react';
import {
  Proposal,
  TProposalsStatus,
  proposalStore
} from '../../../entities/proposal';
import { Card, LayoutWithLoaderAndError, Loader } from '../../../shared';
import { PageHeader } from '../../../widgets/page-header';
import { TabSwitcher, useTabs } from '../../../features/tab-switcher';
import { Pagination } from '../../../features/pagination';
import { ProposalsList } from '../../../features/list-proposals/ui/ProposalsList';

const tabs = ['Active', 'Pending', 'Ended'];

export const Governance = () => {
  const {
    activeProposals,
    activeProposalsPagination,
    pendingProposals,
    pendingProposalsPagination,
    inactiveProposals,
    inactiveProposalsPagination,
    proposalsLoading,
    proposalsError,
    getProposals
  } = proposalStore();
  const { currentTab, handleChangeTab } = useTabs(tabs);

  useEffect(() => {
    getProposals('ongoing');
    getProposals('ended');
    getProposals('pending');
  }, []);

  const handleGetProposals = (status: TProposalsStatus, page: number) => {
    getProposals(status, page);
  };

  return (
    <>
      <PageHeader isHiding>Governance</PageHeader>
      <div className="mb-4">
        <TabSwitcher
          tabs={tabs}
          currentTab={currentTab}
          onChange={handleChangeTab}
        />
      </div>
      {currentTab === 'Active' && (
        <>
          <ProposalsList
            proposals={activeProposals}
            getProposals={(page) => handleGetProposals('ongoing', page)}
            proposalsPagination={activeProposalsPagination}
            isActive={true}
          />
        </>
      )}

      {currentTab === 'Pending' && (
        <>
          <ProposalsList
            proposals={pendingProposals}
            getProposals={(page) => handleGetProposals('pending', page)}
            proposalsPagination={pendingProposalsPagination}
          />
        </>
      )}

      {currentTab === 'Ended' && (
        <>
          <ProposalsList
            proposals={inactiveProposals}
            getProposals={(page) => handleGetProposals('ended', page)}
            proposalsPagination={inactiveProposalsPagination}
          />
        </>
      )}
    </>
  );
};
