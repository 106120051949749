import clsx from 'clsx';
import { VAL_LOGO_FALLBACK } from '../../../shared';
import { FaUser } from 'react-icons/fa6';
import { useState } from 'react';
import ValidatorLogo from '../../../entities/validator/ui/ValidatorLogo';
type NameLogoProps = {
  logo: string;
  moniker: string;
  descr: string | undefined;
};

export const NameLogo = ({ logo, moniker, descr }: NameLogoProps) => {
  return (
    <div className={clsx('h-full flex space-x-4', !descr && 'items-center')}>
      <ValidatorLogo logo={logo} isMainLogo />
      <div
        className={clsx(
          'flex flex-col overflow-hidden',
          descr ? ' justify-center' : 'truncate'
        )}
      >
        <div
          className={clsx(
            'truncate font-bold text-3xl uppercase overflow-hidden'
          )}
        >
          {moniker}
        </div>
        {descr && (
          <div title={descr} className="mt-2.5 font-bold text-xs line-clamp-3">
            {descr}
          </div>
        )}
      </div>
    </div>
  );
};
