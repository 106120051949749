import { DataCardHeader } from './DataCardHeader';

type DataProps = {
  title: string;
  value: string;
};

export const Data = ({ title, value }: DataProps) => {
  return (
    <div className="flex flex-col justify-between h-full">
      <DataCardHeader>{title}</DataCardHeader>
      <div className="sm:text-lg xl:text-xl truncate" title={value}>
        {value}
      </div>
    </div>
  );
};
