import {
  INDEXER_URL,
  ITransaction,
  VALOPERS_URL,
  httpService,
  logger
} from '../../../shared';

import { ITxs, TTransaction } from '../model';

export const transactionApi = {
  getTransactions: async (startHeight: number): Promise<TTransaction[]> => {
    const data: ITxs = await httpService.post(
      VALOPERS_URL + '/getTransactions',
      {
        startHeight,
        num: 30
      }
    );

    const concatenated: TTransaction[] = [];

    Object.keys(data).forEach((height) => {
      const mappedWithHeight = data[height].map((tx) => ({
        ...tx,
        height: Number(height)
      }));

      concatenated.push(...mappedWithHeight);
    });

    return concatenated.sort((a, b) => b.height - a.height);
  },
  getTransactionData: async (txHash: string): Promise<ITransaction> =>
    await httpService.get(INDEXER_URL + '/tx/' + txHash)
};
