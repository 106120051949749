import clsx from 'clsx';
import { TSocialLink } from '../model/socialModel';
import { activeClasses } from '../../../shared';

const bgClasses =
  'bg-gradient-to-b from-neutral-400 via-neutral-700 to-neutral-600 lg:from-neutral-50 lg:via-neutral-200 lg:to-neutral-400';

const sizeClasses = 'w-7 h-7 sm:w-8 sm:h-8 lg:w-9 lg:h-9 2xl:w-10 2xl:h-10';

export const Socialitem = (l: TSocialLink) => {
  return (
    <div
      className={clsx(
        'relative overflow-hidden rounded-full  hover:lg:scale-110 transform transition-all duration-150 lg:shadow-sm lg:shadow-neutral-200',
        bgClasses,
        sizeClasses
      )}
    >
      <a
        key={l.link}
        href={l.link}
        target="_blank"
        rel="noreferrer"
        className={clsx(
          'm-[1px] w-[calc(100%-2px)] h-[calc(100%-2px)] rounded-full flex items-center justify-center bg-black lg:bg-white ',
          activeClasses
        )}
      >
        <l.icon className="text-base lg:text-lg 2xl:text-xl lg:text-black text-white" />
      </a>
    </div>
  );
};
