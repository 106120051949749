import {
  ACCOUNT_ROUTE,
  TRANSACTIONS_ROUTE,
  VALIDATORS_ROUTE
} from './../../../shared/config/routes';
import { Transactions } from './../../../pages/transactions/ui/Transactions';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { logger } from '../../../shared';
import { blockStore } from '../../../entities/block';
import { validatorsStore } from '../../../entities/validator';
import { useState } from 'react';
import { accountStore } from '../../../entities/account';

export const useSearch = () => {
  const navigate = useNavigate();
  const { latestBlockHeight } = blockStore();
  const { findValidatorByAddress } = validatorsStore();
  const { getAccountData } = accountStore();
  const [error, setError] = useState<string | null>(null);
  const [searchInput, setSearchInput] = useState('');

  function handleInput(value: string) {
    setError(null);
    setSearchInput(value);
  }

  function handleBlockHeight(height: string) {
    if (latestBlockHeight && Number(height) > latestBlockHeight) {
      setError('Block height cannot be higher than latest block height');
      return;
    }

    navigate(`/blocks/${height}`);
  }

  async function handleAddress(address: string) {
    // const val = findValidatorByAddress(address);

    // if (val) {
    //   navigate(VALIDATORS_ROUTE + `/${address}`);
    //   return;
    // }

    navigate(ACCOUNT_ROUTE + `/${address}`);
  }

  function handleHash(hash: string) {
    navigate(TRANSACTIONS_ROUTE + `/${hash}`);
  }

  const searchData = () => {
    if (searchInput === '') {
      setError('Please enter address, tx hash or block height');
      return;
    }

    if (/^\d+$/.test(searchInput)) {
      handleBlockHeight(searchInput);
      return;
    }
    if (/^tnam/.test(searchInput) && searchInput.length === 45) {
      handleAddress(searchInput);
      return;
    }
    if (/^[0-9a-f]{64}$/i.test(searchInput)) {
      handleHash(searchInput);
      return;
    }

    setError('Input does not match any expected format');
  };

  return { searchData, error, setError, handleInput, searchInput };
};
