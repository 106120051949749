import { StoreApi, create } from 'zustand';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import {
  IPagination,
  logger,
  ITransaction,
  IDelegations
} from '../../../shared';
import { accountApi } from './accountApi';
import { IBalances } from '../model';

type Store = {
  currentAccAddress: string | null;
  balances: IBalances | null;
  accTransactions: ITransaction[] | null;
  accountError: string | null;
  accTransactionsError: string | null;
  accTransactionsLoading: boolean;
  accountLoading: boolean;
  accTransactionsPagination: IPagination | null;
  publicKeys: string[] | null;
  delegations: IDelegations | null;
  delegationsLoading: boolean;
  delegationsError: string | null;
};

type Actions = {
  getAccountData: (address: string) => Promise<IBalances | undefined>;
  getAccTransactions: (address: string, page: number) => Promise<void>;
  getDelegations: (address: string) => Promise<void>;
};

type AccountStore = Store & Actions;

export const accountStore = create<AccountStore>(
  (
    set: StoreApi<AccountStore>['setState'],
    get: StoreApi<AccountStore>['getState']
  ) => ({
    currentAccAddress: null,
    balances: null,
    publicKeys: null,
    accKeys: null,
    accTransactions: null,
    accountLoading: false,
    accTransactionsLoading: true,
    accountError: null,
    accTransactionsError: null,
    accTransactionsPagination: null,
    delegations: null,
    delegationsLoading: true,
    delegationsError: null,
    getAccountData: async (address) => {
      set({
        currentAccAddress: address,
        balances: null,
        publicKeys: null,
        accountError: null,
        accountLoading: true,
        accTransactionsPagination: null
      });
      try {
        const { balances, publicKeys } = await accountApi.getAccountData(
          address
        );

        set({ balances, publicKeys });

        return balances as IBalances;
      } catch (error) {
        if (error instanceof AxiosError) {
          console.error(error);
          const message =
            'Something went wrong when we tried to get account data';
          set({
            accountError: message
          });
          toast.error(message);
        }
      } finally {
        set({ accountLoading: false });
      }
    },
    getAccTransactions: async (address, page) => {
      set({
        accTransactions: null,
        accTransactionsError: null,
        accTransactionsLoading: true
      });
      // if (get().currentAccAddress !== address) {
      //   set({ accTransactionsPagination: null });
      // }
      try {
        const { data, pagination } = await accountApi.getAccTransactions(
          address,
          page
        );

        set({ accTransactions: data, accTransactionsPagination: pagination });
      } catch (error) {
        if (error instanceof AxiosError) {
          console.error(error);
          const message =
            'Something went wrong when we tried to get account transactions';
          set({
            accTransactionsError: message
          });
          toast.error(message);
        }
      } finally {
        set({ accTransactionsLoading: false });
      }
    },
    getDelegations: async (address: string) => {
      set({
        delegations: null,
        delegationsError: null,
        delegationsLoading: true
      });
      try {
        const delegations = await accountApi.getDelegations(address);

        set({ delegations });
      } catch (error) {
        if (error instanceof AxiosError) {
          console.error(error);
          const message =
            'Something went wrong when we tried to get account delegations';
          set({
            delegationsError: message
          });
          toast.error(message);
        }
      } finally {
        set({ delegationsLoading: false });
      }
    }
  })
);
