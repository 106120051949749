import clsx from 'clsx';
import { PropsWithChildren } from 'react';

type CardProps = {
  color?: 'white' | 'black' | 'yellow';
  className?: string;
  padding?: string;
};

export const Card = ({
  color = 'white',
  children,
  className,
  padding = 'p-4 xl:p-6'
}: PropsWithChildren<CardProps>) => {
  return (
    <div
      className={clsx(
        'rounded-[14px] shadow-[0px_0px_25px_rgba(50,_50,_50,_8%)] overflow-hidden',
        (color === 'white' || color === 'black') &&
          'bg-gradient-to-b from-neutral-200/10 via-neutral-200/10 to-neutral-500/30',
        color === 'yellow' &&
          'bg-gradient-to-b from-primary via-[#e9eb3a] to-[#d7d734]',
        className
      )}
    >
      <div
        className={clsx(
          'm-[2px] rounded-xl w-[calc(100%-4px)] h-[calc(100%-4px)] relative',
          padding,
          color === 'white' && 'bg-white text-black',
          color === 'black' && 'bg-black text-white',
          color === 'yellow' && 'bg-primary text-black'
        )}
      >
        {children}
      </div>
    </div>
  );
};
