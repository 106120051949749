import clsx from 'clsx';
import { Loader } from './Loader';
import { Card } from './Card';
import { DataCardHeader } from './DataCardHeader';

type DataCardProps = {
  name: string;
  data: string | number | null;
  isLoading?: boolean;
  error: string | null;
};

export const DataWLoader = ({
  name,
  data,
  isLoading,
  error
}: DataCardProps) => {
  return (
    <div className="flex flex-col justify-between h-full">
      <DataCardHeader mb="mb-2 sm:mb-0">{name}</DataCardHeader>
      <div
        className={clsx(
          'sm:text-lg xl:text-xl font-light truncate',
          isLoading && 'flex justify-center items-center pt-3'
        )}
      >
        {isLoading === true ? <Loader /> : error ? 'Fetch error' : data}
      </div>
    </div>
  );
};
