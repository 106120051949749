import { useState } from 'react';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { validatorsStore } from '../../../entities/validator';
import { IBlockData, IBlockExtData, blocksApi } from '../../../entities/block';

export const useBlockData = () => {
  const [blockData, setBlockData] = useState<IBlockData | null>(null);
  const [blockDataLoading, setBlockDataLoading] = useState(false);
  const [blockDataError, setBlockDataError] = useState<string | null>(null);

  const { getValidators, findValidatorByTendermint } = validatorsStore();

  const getBlockData = async (height: number) => {
    setBlockDataLoading(true);
    setBlockDataError(null);

    try {
      await getValidators();

      const { blocks } = await blocksApi.getBlocks({
        startHeight: height,
        num: 1
      });

      const data = await blocksApi.getBlockData(height);

      const proposer = findValidatorByTendermint(data.header.proposer_address);

      const blockData = {
        ...data,
        signatures: blocks[0].signatures,
        proposer
      };

      setBlockData(blockData);
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error(error);
        const message = 'Something went wrong when we tried to get block data';
        setBlockDataError(message);
        toast.error(message);
      }
    } finally {
      setBlockDataLoading(false);
    }
  };

  return {
    blockData,
    blockDataLoading,
    blockDataError,
    getBlockData
  };
};
