import clsx from 'clsx';
import { LegacyRef, MutableRefObject, useEffect, useRef } from 'react';
import { FiSearch } from 'react-icons/fi';

type TableSearchProps = {
  onChange: (value: string) => void;
  value: string;
  isOpen: boolean;
  onToggle: (isOpened: boolean) => void;
  placeholder: string;
};

export const TableSearch = ({
  value,
  onChange,
  isOpen,
  onToggle,
  placeholder
}: TableSearchProps) => {
  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        input.current && input.current.focus();
      }, 300);
    }
  }, [isOpen]);

  return (
    <div
      className={clsx(
        'h-8 absolute z-50 rounded-full top-2 left-2 flex items-center p-2 transition-all duration-300 overflow-hidden w-64',
        isOpen ? '' : 'bg-transparent'
      )}
    >
      <FiSearch
        onClick={() => onToggle(!isOpen)}
        className={clsx(
          'text-lg block cursor-pointer transition-all duration-300 z-50',
          !isOpen && 'text-white'
        )}
      />
      <div
        className={clsx(
          'absolute top-0 left-0 h-full transition-all duration-300 origin-left flex items-center bg-white rounded-full px-1.5',
          isOpen ? 'scale-x-100' : 'scale-x-0'
        )}
      >
        <input
          ref={input}
          id="search"
          placeholder={placeholder}
          className={clsx(
            'ml-8 rounded-full px-2 py-0.5 text-sm focus:outline-none'
          )}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    </div>
  );
};
