import { useEffect, useState } from 'react';
import { IProposal } from '../model';
import clsx from 'clsx';
import { VotesBar } from './VotesBar';
import { blockStore } from '../../block';
import { LinkWrap, PROPOSAL_ROUTE, logger } from '../../../shared';
import { EmptyVotesBar } from './EmptyVotesBar';
import { isGrace } from '../lib';
import { useNavigate } from 'react-router';

type ProposalProps = IProposal & { isActive?: boolean };

export const Proposal = ({ isActive, ...proposal }: ProposalProps) => {
  const { epoch } = blockStore();
  const [result, _] = useState<any>(
    proposal.result ? JSON.parse(proposal.result) : null
  );
  const [content, __] = useState<any | undefined>(
    proposal.content ? JSON.parse(proposal.content) : null
  );
  const nav = useNavigate();

  return (
    <div
      onClick={() => nav(PROPOSAL_ROUTE + '/' + proposal.proposal_id)}
      className="xl:hover:bg-neutral-200 cursor-pointer rounded-lg p-2"
    >
      <div className="flex justify-between items-center mb-1 ">
        <div className="flex items-center text-lg">
          <div className="mr-3 font-light">#{proposal.proposal_id}</div>
          {result && (
            <div className={clsx('uppercase font-light')}>
              <span
                className={clsx(
                  'uppercase font-light mr-3 inline-block',
                  result &&
                    (result.outcome === 'active' || result.outcome === 'passed')
                    ? 'text-green-600'
                    : 'text-red-500'
                )}
              >
                {result && result.outcome === 'active' && isActive
                  ? 'Voting period'
                  : result.outcome}
              </span>
            </div>
          )}
        </div>

        <div className="flex items-center gap-4">
          <div>
            {isActive &&
              isGrace(
                Number(epoch?.latestCommited) ?? 0,
                proposal.end_epoch,
                proposal.grace_epoch
              ) && (
                <span
                  className={clsx(
                    'px-2 py-0.5 rounded-full text-xs normal-case w-max text-center block text-white bg-black'
                  )}
                >
                  Grace
                </span>
              )}
          </div>
          <div className="text-xs">Start / End</div>
        </div>
      </div>

      <div className="flex justify-between items-center text-xs mb-2.5">
        <div className="truncate mr-2">
          {content?.title || 'proposal without title'}
        </div>
        <div>
          {proposal.start_epoch}/{proposal.end_epoch}
        </div>
      </div>
      {result ? (
        <VotesBar yay={result.yay} nay={result.nay} abstain={result.abstain} />
      ) : (
        <EmptyVotesBar />
      )}
    </div>
  );
};
