import { useEffect, useState } from 'react';
import { TableCell, formatNumber } from '../../../shared';

type TokenRowProps = {
  token: string;
  amount: string | number;
};

export const TokenRow = ({ token, amount }: TokenRowProps) => {
  const [tokenName, setTokenName] = useState<string>();
  const [tokenDescr, setTokenDescr] = useState<string>();

  const getTokenName = (token: string) => {
    if (token === 'naan') {
      setTokenName('NAAN');
      setTokenDescr('Staking token');
    } else {
      if (token.includes('transfer')) {
        const splitted = token.split('/');

        setTokenName(splitted[2]);
        setTokenDescr(splitted[1]);
      } else {
        setTokenName(token);
        setTokenDescr('');
      }
    }
  };

  useEffect(() => {
    getTokenName(token);
  }, []);

  return (
    <>
      <TableCell className="py-4">
        <div className="uppercase text-lg">{tokenName}</div>
        <div>{tokenDescr}</div>
      </TableCell>
      <TableCell className="py-4">{formatNumber(amount, true)}</TableCell>
      <TableCell className="py-4">-</TableCell>
    </>
  );
};
