import { StoreApi, create } from 'zustand';
import { computed } from 'zustand-middleware-computed-state';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { IBlock, IBlocksPayload, IEpochResponse } from './blockTypes';
import { blocksApi } from '../api';
import { BLOCKSNUM, WS_URL, logger } from '../../../shared';

type Store = {
  latestBlock: IBlock | null;
  latestBlockHeight: number | null;
  latestBlockTime: Date | null;
  networkId: string | null;
  statusError: string | null;
  statusLoading: boolean;
  epoch: IEpochResponse | null;
  epochError: string | null;
  epochLoading: boolean;
  uptime: { [key: string]: number } | null;
  blocks: IBlock[];
  blocksError: string | null;
  blocksLoading: boolean;
};

type Actions = {
  getEpoch: () => Promise<void>;
  getStatus: () => Promise<void>;
  getBlocks: (payload: IBlocksPayload) => Promise<IBlock[] | undefined>;
  getLatestBlocks: () => Promise<void>;
  addNewBlock: (height: number) => void;
};

type BlockStore = Store & Actions;

type ComputedStore = {
  isStatusLoading: boolean;
  latestBlocks: IBlock[];
  sortedBlocks: IBlock[];
};

const computedStore = (state: BlockStore): ComputedStore => {
  const isStatusLoading = state.statusLoading && state.epochLoading;

  const sortedBlocks = [...state.blocks].sort(
    (a, b) => b.block_height - a.block_height
  );

  sortedBlocks.length = BLOCKSNUM;

  const latestBlocks = [...sortedBlocks];

  if (latestBlocks.length >= 15) latestBlocks.length = 15;

  return {
    isStatusLoading,
    latestBlocks,
    sortedBlocks
  };
};

export const blockStore = create<BlockStore & ComputedStore>(
  computed<BlockStore, ComputedStore>(
    (
      set: StoreApi<BlockStore>['setState'],
      get: StoreApi<BlockStore>['getState']
    ) => {
      const ws = new WebSocket(WS_URL);
      ws.addEventListener('open', () => {
        ws.send(
          JSON.stringify({
            jsonrpc: '2.0',
            method: 'subscribe',
            id: 0,
            params: {
              query: "tm.event='NewBlock'"
            }
          })
        );
      });

      ws.addEventListener('close', async (info) => {
        logger.info('ws closed', info);
        if (get().latestBlockHeight === null) {
          await get().getStatus();
        }
      });
      ws.addEventListener('ws error', async (error) => {
        logger.error('ws error', error);
        if (get().latestBlockHeight === null) {
          await get().getStatus();
        }
        return;
      });

      ws.addEventListener('message', async ({ data }) => {
        const { result } = JSON.parse(data);
        if (result?.data?.value) {
          const blockData = result.data.value.block;
          const latestBlockHeight = blockData.header.height;
          const networkId = blockData.header.chain_id;
          const latestBlockTime = blockData.header.time;
          if (get().latestBlockHeight === null) {
            set({
              latestBlockHeight,
              networkId,
              latestBlockTime,
              statusLoading: false,
              latestBlock: {
                block_id: blockData.last_commit.block_id.hash,
                block_height: latestBlockHeight,
                time: latestBlockTime,
                total_tx: blockData.data.txs.length,
                proposer_address: blockData.header.proposer_address,
                signatures: blockData.last_commit.signatures
              }
            });
            await get().getLatestBlocks();
          } else {
            get().addNewBlock(latestBlockHeight);
          }
          set({
            latestBlockHeight,
            networkId,
            latestBlockTime,
            statusLoading: false,
            latestBlock: {
              block_id: blockData.last_commit.block_id.hash,
              block_height: latestBlockHeight,
              time: latestBlockTime,
              total_tx: blockData.data.txs.length,
              proposer_address: blockData.header.proposer_address,
              signatures: blockData.last_commit.signatures
            }
          });
        } else {
          if (get().latestBlockHeight === null) {
            await get().getStatus();
          }
        }
      });

      return {
        latestBlock: null,
        latestBlockHeight: null,
        latestBlockTime: null,
        networkId: null,
        status: null,
        statusError: null,
        statusLoading: true,
        epoch: null,
        epochError: null,
        epochLoading: true,
        uptime: null,
        blocks: [],
        blocksError: null,
        blocksLoading: true,
        getStatus: async () => {
          set({
            statusError: null
          });
          try {
            const { result } = await blocksApi.getStatus();

            set({
              latestBlockHeight: result.sync_info.latest_block_height,
              latestBlockTime: result.sync_info.latest_block_time,
              networkId: result.node_info.network
            });

            get().getLatestBlocks();
          } catch (error) {
            if (error instanceof AxiosError) {
              console.error(error);
              const message =
                'Something went wrong when we tried to get status data';
              set({
                statusError: message
              });
              toast.error(message);
            }
          } finally {
            set({ statusLoading: false });
          }
        },
        getEpoch: async () => {
          set({
            epochError: null
          });
          try {
            const epoch = await blocksApi.getEpoch();

            set({
              epoch
            });
          } catch (error) {
            if (error instanceof AxiosError) {
              console.error(error);
              const message = 'Something went wrong when we tried to get epoch';
              set({ statusError: message });
              toast.error(message);
            }
          } finally {
            set({ epochLoading: false });
          }
        },
        getBlocks: async (payload: IBlocksPayload) => {
          set({ blocksError: null });
          try {
            const { blocks, uptime } = await blocksApi.getBlocks(payload);

            blocks.sort((a, b) => a.block_height - b.block_height);

            // logger.debug('blocks', blocks);

            return blocks;
          } catch (error) {
            if (error instanceof AxiosError) {
              console.error(error);
              const message =
                'Something went wrong when we tried to get the latest blocks';
              set({
                blocksError: message
              });
              toast.error(message);
            }
          }
        },
        getLatestBlocks: async () => {
          // logger.debug('getting latest blocks...', '');
          set({
            blocksError: null
          });

          const latestBlock = get().latestBlockHeight;

          if (latestBlock) {
            try {
              const { blocks, uptime } = await blocksApi.getBlocks({
                startHeight: latestBlock,
                num: BLOCKSNUM
              });

              blocks.sort((a, b) => b.block_height - a.block_height);

              // logger.debug('blocks', blocks);

              set({
                blocks,
                uptime
              });
            } catch (error) {
              if (error instanceof AxiosError) {
                console.error(error);
                // const message =
                //   'Something went wrong when we tried to get the latest blocks';
                // set({
                //   blocksError: message
                // });
                // toast.error(message);
              }
            } finally {
              set({ blocksLoading: false });
            }
          }
        },
        addNewBlock: async (height) => {
          // logger.debug('adding new block...', '');

          if (get().blocks.length === 0) {
            // logger.debug('no blocks yet', '');
            return;
          }

          const blocks = [...get().blocks];

          blocks.sort((a, b) => a?.block_height - b?.block_height);

          const blockIndex = blocks.findIndex(
            (b) => Number(b?.block_id) === height
          );

          if (blockIndex !== -1) {
            // logger.debug('block is already in array', height);
            return;
          }

          const change = height - blocks[blocks.length - 1].block_height;

          // logger.debug('change', change);

          try {
            const newData = await get().getBlocks({
              startHeight: height,
              num: change
            });

            // logger.debug('newData', newData);

            if (!newData) return;

            const concatenated = blocks.concat(newData as IBlock[]);

            set({
              blocks: [...new Set(concatenated)]
            });
          } catch (error) {
            logger.error("We couldn't add a new block", error);
          }
        }
      };
    },
    computedStore
  )
);
