import { validatorsStore } from '../../../entities/validator';
import { Header, Loader } from '../../../shared';
import ValidatorsTable from './ValidatorsTable';

const TopValidators = () => {
  const { topValidators, validatorsLoading, validatorsError } =
    validatorsStore();

  return (
    <>
      <Header className="mb-4 xl:mb-10">Top validators</Header>
      {validatorsLoading ? (
        <div className="flex space-x-5 justify-center mt-5">
          <Loader size={50} />
        </div>
      ) : validatorsError ? (
        <div className="flex space-x-5 justify-center mt-5 text-xl mb-12">
          {validatorsError}
        </div>
      ) : (
        <ValidatorsTable validators={topValidators} />
      )}
    </>
  );
};

export default TopValidators;
