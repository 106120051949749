import { useEffect, useMemo, useState } from 'react';
import { blockStore } from '../../../entities/block';
import { validatorsStore } from '../../../entities/validator';
import { Card, Nothing, activeClasses } from '../../../shared';
import clsx from 'clsx';
import { ValidatorBlocksUptime } from './ValidatorBlocksUptime';
import { initialShrinkedBlocks, useValidatorBlocksUptime } from '../model';
import { ValUptime } from './ValUptime';
import { FiSearch } from 'react-icons/fi';
import { CardSearch } from '../../../shared/ui/CardSearch';

export const UptimeDisplay = () => {
  const {
    activeValidators,
    validatorsLoading,
    validatorsError,
    uptimeIndex,
    setUptimeIndex
  } = validatorsStore();
  const { sortedBlocks, latestBlock, blocks, blocksLoading } = blockStore();

  const [isScaled, setIsScaled] = useState(false);

  const handleScaleView = () => {
    setIsScaled((prev) => !prev);
  };

  const { calculateUptimeBlocks } = useValidatorBlocksUptime();

  const uptimeBlocks = useMemo(
    () => calculateUptimeBlocks(sortedBlocks, activeValidators),
    [activeValidators, blocks]
  );

  const startCounting = () => {
    const interval = setInterval(() => {
      setUptimeIndex();
    }, 200);

    if (uptimeIndex === 50) clearInterval(interval);
  };

  useEffect(() => {
    if (blocks.length > 0 && uptimeIndex === 0) {
      startCounting();
    }
  }, [activeValidators, blocks]);

  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState(uptimeBlocks);

  const handleChangeSearchInput = (value: string) => {
    setSearchInput(value);
  };

  useEffect(() => {
    if (searchInput === '') {
      setFilteredData(uptimeBlocks);
    } else {
      const filtered =
        uptimeBlocks &&
        uptimeBlocks.filter(
          (u) =>
            u.address.includes(searchInput.toLowerCase()) ||
            u.moniker.toLowerCase().includes(searchInput.toLowerCase())
        );
      setFilteredData(filtered);
    }
  }, [searchInput]);

  return (
    <div className="relative">
      <div className="flex justify-between mb-4">
        <button
          onClick={handleScaleView}
          className={clsx(
            'hidden xl:block bg-black text-primary hover:bg-primary hover:text-black px-2 py-1 rounded-full shadow text-sm w-20',
            activeClasses
          )}
        >
          {isScaled ? 'Unscale' : 'Scale'}
        </button>
      </div>
      <Card>
        {!isScaled && (
          <CardSearch
            searchInput={searchInput}
            onChange={handleChangeSearchInput}
            placeholder="address or moniker"
          />
        )}
        <div
          className={clsx(
            'grid',
            isScaled
              ? 'xl:grid-cols-9 2xl:grid-cols-12 xl:gap-1 2xl:gap-1.5'
              : 'gap-4 xl:gap-6',
            filteredData && filteredData.length === 0
              ? 'grid-cols-1'
              : 'grid-cols-2 xl:grid-cols-3'
          )}
        >
          {activeValidators && filteredData
            ? filteredData.map((v, ind) => (
                <ValUptime
                  key={v.address}
                  {...v}
                  ind={ind}
                  isScaled={isScaled}
                  blockIndex={uptimeIndex}
                />
              ))
            : activeValidators.map((v, ind) => (
                <ValUptime
                  key={v.address}
                  address={v.address}
                  moniker={v.moniker}
                  blocks={initialShrinkedBlocks}
                  ind={ind}
                  isScaled={isScaled}
                  blockIndex={uptimeIndex}
                />
              ))}
          {activeValidators && filteredData && filteredData.length === 0 && (
            <Nothing noShadow />
          )}
        </div>
      </Card>
    </div>
  );
};
