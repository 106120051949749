import { StoreApi, create } from 'zustand';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { IPagination, logger } from '../../../shared';
import { proposalsApi } from '../api';
import { IProposal, IProposalData, TProposalsStatus } from './proposalTypes';

type Store = {
  activeProposals: IProposal[] | null;
  inactiveProposalsPagination: IPagination | null;
  inactiveProposals: IProposal[] | null;
  activeProposalsPagination: IPagination | null;
  pendingProposals: IProposal[] | null;
  pendingProposalsPagination: IPagination | null;
  proposalsLoading: boolean;
  proposalsError: string | null;
  proposalData: IProposalData | null;
  proposalDataLoading: boolean;
  proposalDataError: string | null;
};

type Actions = {
  getProposals: (status: TProposalsStatus, page?: number) => Promise<void>;
  getProposalData: (id: string) => Promise<void>;
};

type ProposalStore = Store & Actions;

export const proposalStore = create<ProposalStore>(
  (
    set: StoreApi<ProposalStore>['setState'],
    get: StoreApi<ProposalStore>['getState']
  ) => ({
    activeProposals: null,
    activeProposalsPagination: null,
    inactiveProposals: null,
    inactiveProposalsPagination: null,
    pendingProposals: null,
    pendingProposalsPagination: null,
    proposalsV1: null,
    proposalsLoading: true,
    proposalsError: null,
    proposalData: null,
    proposalDataLoading: true,
    proposalDataError: null,
    getProposalData: async (id) => {
      set({
        proposalData: null,
        proposalDataLoading: true,
        proposalDataError: null
      });
      try {
        const proposalData = await proposalsApi.getProposal(id);

        logger.debug('proposal', proposalData);

        set({ proposalData });
      } catch (error) {
        if (error instanceof AxiosError) {
          console.error(error);
          const message =
            'Something went wrong when we tried to get proposal data';
          set({
            proposalDataError: message
          });
          toast.error(message);
        }
      } finally {
        set({ proposalDataLoading: false });
      }
    },
    getProposals: async (status, page = 1) => {
      set({
        proposalsError: null,
        proposalsLoading: true
      });
      if (status === 'ongoing') set({ activeProposals: null });
      if (status === 'ended') set({ inactiveProposals: null });
      if (status === 'pending') set({ pendingProposals: null });

      try {
        const { proposals, pagination } = await proposalsApi.getProposals(
          status,
          page
        );

        if (status === 'ongoing')
          set({
            activeProposals: proposals,
            activeProposalsPagination: pagination
          });
        if (status === 'ended')
          set({
            inactiveProposals: proposals,
            inactiveProposalsPagination: pagination
          });
        if (status === 'pending')
          set({
            pendingProposals: proposals,
            pendingProposalsPagination: pagination
          });
      } catch (error) {
        if (error instanceof AxiosError) {
          console.error(error);
          const message =
            'Something went wrong when we tried to get account proposals';
          set({
            proposalsError: message
          });
          toast.error(message);
        }
      } finally {
        set({ proposalsLoading: false });
      }
    }
  })
);
