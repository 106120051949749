import { useEffect, useState } from 'react';
import { ValidatorRow } from '../../../entities/validator';
import { IValidator } from '../../../entities/validator/model/validatorTypes';
import { TableSearch, useTableSearch } from '../../../features/table-search';
import { TableRow, TableWithHeaders, Nothing } from '../../../shared';

type ValidatorsProps = {
  validators: IValidator[];
};

const headers = [
  { name: '#', align: 'text-left' },
  { name: 'Moniker', align: 'text-left' },
  { name: 'Amount', align: 'text-left' },
  { name: 'Share', align: 'text-left' },
  { name: 'Commission', align: 'text-left' },
  { name: 'Uptime', align: 'text-left' }
];

export const ValidatorsTable = ({ validators }: ValidatorsProps) => {
  const {
    searchInput,
    searchIsOpened,
    handleChangeSearchInput,
    handleOpenSearch,
    filteredData
  } = useTableSearch(validators, (v) => {
    if (typeof v === 'object' && 'moniker' in v) {
      return (
        v.address.includes(searchInput.toLowerCase()) ||
        v.moniker.toLowerCase().includes(searchInput.toLowerCase())
      );
    } else return false;
  });

  return (
    <div className="relative">
      <TableSearch
        value={searchInput}
        onChange={handleChangeSearchInput}
        isOpen={searchIsOpened}
        onToggle={handleOpenSearch}
        placeholder="address or moniker"
      />
      <TableWithHeaders headers={headers} withSearch>
        {filteredData &&
          (filteredData as IValidator[]).map((v, ind) => (
            <TableRow key={v.address} withSearch>
              <ValidatorRow {...v} index={ind + 1} />
            </TableRow>
          ))}
      </TableWithHeaders>
      {filteredData && filteredData.length === 0 && <Nothing />}
    </div>
  );
};

export default ValidatorsTable;
