import { PropsWithChildren } from 'react';
import { Loader } from './Loader';

type Props = {
  isLoading: boolean;
  error: string | null | undefined;
  noError?: boolean;
};

export const LayoutWithLoaderAndError = ({
  isLoading,
  error,
  children,
  noError
}: PropsWithChildren<Props>) => {
  if (isLoading)
    return (
      <div className="flex space-x-5 justify-center">
        <Loader size={50} />
      </div>
    );

  if (error && !noError)
    return <div className="flex space-x-5 justify-center">{error}</div>;

  return <div>{children}</div>;
};
