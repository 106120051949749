import { IBlock } from '../../../entities/block/model/blockTypes';
import { BlockRow } from '../../../entities/block';
import { TableWithHeaders, TableRow, Nothing } from '../../../shared';
import { TableSearch, useTableSearch } from '../../table-search';

type BlocksDisplayProps = {
  blocks: IBlock[];
};

const headers = [
  { name: 'Height', align: 'text-left' },
  { name: 'Hash', align: 'text-left' },
  { name: 'Proposer', align: 'text-left' },
  { name: 'Txs', align: 'text-left' },
  { name: 'Time', align: 'text-left' }
];

export const BlocksTable = ({ blocks }: BlocksDisplayProps) => {
  const {
    searchInput,
    searchIsOpened,
    handleChangeSearchInput,
    handleOpenSearch,
    filteredData
  } = useTableSearch(blocks, (b) => {
    if (typeof b === 'object' && 'block_height' in b) {
      return (
        b.block_height.toString().includes(searchInput.toLowerCase()) ||
        b.block_id.toLowerCase().includes(searchInput.toLowerCase())
      );
    } else {
      return false;
    }
  });

  return (
    <div className="relative">
      <TableSearch
        value={searchInput}
        onChange={handleChangeSearchInput}
        isOpen={searchIsOpened}
        onToggle={handleOpenSearch}
        placeholder="block id or height"
      />
      <TableWithHeaders headers={headers}>
        {(filteredData as IBlock[]).map((b) => (
          <TableRow key={b.block_id}>
            <BlockRow {...b} />
          </TableRow>
        ))}
      </TableWithHeaders>
      {filteredData && filteredData.length === 0 && <Nothing />}
    </div>
  );
};
