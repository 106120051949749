import { FaRegCopy } from 'react-icons/fa';
import {
  ACCOUNT_ROUTE,
  DataCardHeader,
  LinkWrap,
  activeClasses,
  handleCopyValue
} from '..';
import clsx from 'clsx';

type AddressBlockProps = {
  title: string;
  value: string | null;
  isAccLink?: boolean;
  isLoading?: boolean;
};

export const AddressBlock = ({
  title,
  value,
  isAccLink,
  isLoading
}: AddressBlockProps) => {
  return (
    <div>
      <div
        className={clsx(
          'mb-2 sm:mb-2 flex items-center space-x-4 uppercase',
          isLoading && 'animate-pulse'
        )}
      >
        <div className="font-medium text-lg sm:text-xl xl:text-2xl">
          {title}
        </div>
        <button onClick={() => value && handleCopyValue(value)}>
          <FaRegCopy className={clsx('sm:text-lg xl:text-xl', activeClasses)} />
        </button>
      </div>
      {isAccLink ? (
        <LinkWrap
          className={clsx(
            'truncate text-base sm:text-lg xl:text-xl !underline',
            isLoading && 'animate-pulse'
          )}
          to={ACCOUNT_ROUTE + '/' + value}
        >
          {value}
        </LinkWrap>
      ) : (
        <div className="truncate text-base sm:text-lg xl:text-xl">{value}</div>
      )}
    </div>
  );
};
