import { StoreApi, create } from 'zustand';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { logger } from '../../../shared';
import { voteApi } from '../api';
import { IProposalVotes, IVote } from './voteTypes';

type Store = {
  accVotes: IVote[] | null;
  accVotesLoading: boolean;
  accVotesError: string | null;
  proposalVotes: IProposalVotes | null;
  proposalVotesLoading: boolean;
  proposalVotesError: string | null;
};

type Actions = {
  getVotes: (address: string) => Promise<void>;
  getProposalVotes: (id: string) => Promise<void>;
};

type VoteStore = Store & Actions;

export const voteStore = create<VoteStore>(
  (
    set: StoreApi<VoteStore>['setState'],
    get: StoreApi<VoteStore>['getState']
  ) => ({
    accVotes: null,
    accVotesLoading: true,
    accVotesError: null,
    proposalVotes: null,
    proposalVotesLoading: true,
    proposalVotesError: null,
    getVotes: async (address: string) => {
      set({
        accVotes: null,
        accVotesError: null,
        accVotesLoading: true
      });
      try {
        const votes = await voteApi.getVotes(address);

        set({ accVotes: votes });
      } catch (error) {
        if (error instanceof AxiosError) {
          console.error(error);
          const message =
            'Something went wrong when we tried to get account votes';
          set({
            accVotesError: message
          });
          toast.error(message);
        }
      } finally {
        set({ accVotesLoading: false });
      }
    },
    getProposalVotes: async (id) => {
      set({
        proposalVotes: null,
        proposalVotesError: null,
        proposalVotesLoading: true
      });
      try {
        const proposalVotes = await voteApi.getProposalVotes(id);

        logger.debug('propo votes', proposalVotes);

        set({ proposalVotes });
      } catch (error) {
        if (error instanceof AxiosError) {
          console.error(error);
          const message =
            'Something went wrong when we tried to get account votes';
          set({
            accVotesError: message
          });
          toast.error(message);
        }
      } finally {
        set({ proposalVotesLoading: false });
      }
    }
  })
);
