import { IVote } from '../../../entities/vote';
import {
  TableRow,
  TableWithHeaders,
  SkeletonRow,
  TableCell,
  Nothing
} from '../../../shared';
import { TableSearch, useTableSearch } from '../../../features/table-search';
import { AccVoteRow } from './AccVoteRow';
import { useEffect } from 'react';

type VotesProps = {
  votes: IVote[] | null;
  isLoading: boolean;
};

const headers = [
  { name: 'Proposal', align: 'text-left' },
  { name: 'Vote', align: 'text-left' }
];

export const AccVotesTable = ({ votes, isLoading }: VotesProps) => {
  const {
    searchInput,
    searchIsOpened,
    handleChangeSearchInput,
    handleOpenSearch,
    filteredData
  } = useTableSearch(votes as IVote[], (v) => {
    if (typeof v === 'object' && 'proposal_id' in v) {
      return (
        (v.vote?.includes(searchInput.toLowerCase()) ||
          v.proposal_id.toString().includes(searchInput.toLowerCase()) ||
          v.title?.toLowerCase().includes(searchInput.toLowerCase())) ??
        false
      );
    } else return false;
  });

  return (
    <div className="relative">
      <TableSearch
        value={searchInput}
        onChange={handleChangeSearchInput}
        isOpen={searchIsOpened}
        onToggle={handleOpenSearch}
        placeholder="proposal id or title"
      />
      <TableWithHeaders
        headers={headers}
        isLoading={isLoading}
        hasScroll={true}
      >
        {isLoading ? (
          <TableRow hasScroll={true}>
            <SkeletonRow cellsCount={2} />
          </TableRow>
        ) : (
          votes &&
          filteredData &&
          (filteredData as IVote[]).map((v) => (
            <TableRow key={`${v.proposal_id}-${v.title}`} hasScroll={true}>
              <AccVoteRow {...v} />
            </TableRow>
          ))
        )}
        {!isLoading && filteredData && filteredData.length === 0 && <Nothing />}
      </TableWithHeaders>
    </div>
  );
};
