import { accountStore } from '../../../entities/account';
import { DelegationsTable } from '../../../features/list-delegations';
import { TabSwitcher, useTabs } from '../../../features/tab-switcher';
import { IDelegations } from '../../../shared';

const tabs = ['Bonded', 'Unbonded'];

type DelegationsProps = {
  delegations: IDelegations | null;
  isLoading: boolean;
  isDelegator?: boolean;
};

export const Delegations = ({
  delegations,
  isLoading,
  isDelegator
}: DelegationsProps) => {
  const { currentTab, handleChangeTab } = useTabs(tabs);

  return (
    <div>
      <div className="mb-4">
        <TabSwitcher
          tabs={tabs}
          onChange={handleChangeTab}
          currentTab={currentTab}
        />
      </div>
      {currentTab === 'Bonded' && (
        <DelegationsTable
          delegations={delegations?.bonded}
          isLoading={isLoading}
          isDelegator={isDelegator}
        />
      )}
      {currentTab === 'Unbonded' && (
        <DelegationsTable
          delegations={delegations?.unbonded}
          isLoading={isLoading}
          isDelegator={isDelegator}
        />
      )}
    </div>
  );
};
