import clsx from 'clsx';
import { PropsWithChildren } from 'react';

type TableCellProps = {
  className?: string;
  center?: boolean;
};

export const TableCell = ({
  children,
  className,
  center
}: PropsWithChildren<TableCellProps>) => {
  return (
    <td
      className={clsx(
        'text-sm sm:text-base',
        className || 'py-4',
        center && 'text-center'
      )}
    >
      {children}
    </td>
  );
};
