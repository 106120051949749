import { useEffect } from 'react';

import { LayoutWithLoaderAndError } from '../../../shared';
import { parameterStore } from '../../../entities/parameter';
import { ParametersCard } from './ParametersCard';
import { PageHeader } from '../../../widgets/page-header';

export const Parameters = () => {
  const { parameters, parametersLoading, parametersError } = parameterStore();

  return (
    <LayoutWithLoaderAndError
      isLoading={parametersLoading}
      error={parametersError}
    >
      <PageHeader isHiding>Parameters</PageHeader>
      {parameters && (
        <>
          <div className="space-y-6 md:space-y-0 md:grid grid-cols-2 gap-4 xl:gap-6 mb-6 xl:mb-8">
            <ParametersCard
              title="Governance"
              params={parameters.governance_parameters}
            />
            <ParametersCard title="Pos" params={parameters.pos_parameters} />
          </div>
          <div className="space-y-6 md:space-y-0 md:grid grid-cols-2 gap-4 xl:gap-6">
            <ParametersCard
              title="Protocol"
              params={parameters.protocol_parameters}
            />
            <ParametersCard
              title="Public Goods Funding"
              params={parameters.public_goods_funding_parameters}
            />
          </div>
        </>
      )}
    </LayoutWithLoaderAndError>
  );
};
