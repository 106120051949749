import { BlocksTable } from '../../../features/list-blocks';

import { LayoutWithLoaderAndError } from '../../../shared/ui';

import { blockStore } from '../../../entities/block';
import { PageHeader } from '../../../widgets/page-header';

export const Blocks = () => {
  const { sortedBlocks, blocksLoading, blocksError } = blockStore();

  return (
    <LayoutWithLoaderAndError
      isLoading={blocksLoading}
      error={blocksError}
      noError={true}
    >
      <PageHeader isHiding>Blocks</PageHeader>
      <BlocksTable blocks={sortedBlocks} />
    </LayoutWithLoaderAndError>
  );
};
