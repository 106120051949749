import clsx from 'clsx';
import { PropsWithChildren } from 'react';

type DataCardHeaderProps = {
  mb?: string;
};

export const DataCardHeader = ({
  children,
  mb = 'mb-6 xl:mb-14'
}: PropsWithChildren<DataCardHeaderProps>) => {
  return (
    <div
      className={clsx(
        'text-lg sm:text-xl xl:text-2xl font-medium uppercase whitespace-normal',
        mb
      )}
    >
      {children}
    </div>
  );
};
