import { memo } from 'react';
import {
  IProposal,
  Proposal,
  ProposalSkeleton,
  proposalStore
} from '../../../entities/proposal';
import { Card, IPagination, Loader, Nothing } from '../../../shared';
import { Pagination, usePagination } from '../../pagination';
import clsx from 'clsx';

const skeletonProposals: number[] = [];

for (let i = 0; i < 16; i++) {
  skeletonProposals.push(1);
}

type ProposalsListProps = {
  proposals: IProposal[] | null;
  getProposals: (page: number) => void;
  proposalsPagination: IPagination | null;
  isActive?: boolean;
};

const ProposalsListComponent = ({
  proposals,
  getProposals,
  proposalsPagination,
  isActive
}: ProposalsListProps) => {
  const { proposalsLoading } = proposalStore();

  const pagination = usePagination(
    proposalsPagination?.totalPages as number,
    (page) => getProposals(page)
  );

  return (
    <>
      <Card>
        <div
          className={clsx(
            'grid grid-cols-1 md:grid-cols-2 gap-4 xl:gap-5 relative'
            // !proposalsLoading &&
            //   ((proposals && proposals.length === 0) || !proposals)
            //   ? 'block h-48'
            //   : 'grid'
          )}
        >
          {proposalsLoading ? (
            skeletonProposals.map((p, ind) => (
              <ProposalSkeleton key={`skeleton-${ind}`} />
            ))
          ) : proposals && proposals.length > 0 ? (
            proposals.map((p) => (
              <Proposal key={p.proposal_id} {...p} isActive={isActive} />
            ))
          ) : (
            <>
              <div className="absolute w-full h-full opacity-100 z-50">
                <Nothing isCentered={false} />
              </div>
              {skeletonProposals.map((p, ind) => (
                <ProposalSkeleton key={`skeleton-${ind}`} />
              ))}
            </>
          )}
        </div>
      </Card>
      {pagination.totalPages > 1 && <Pagination {...pagination} />}
    </>
  );
};

export const ProposalsList = memo(ProposalsListComponent);
