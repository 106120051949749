import clsx from 'clsx';
import { PropsWithChildren } from 'react';

type TableWithHeadersProps = {
  headers: { name: string; align: string }[];
  isLoading?: boolean;
  hasScroll?: boolean;
  withSearch?: boolean;
};

export const TableWithHeaders = ({
  children,
  headers,
  isLoading,
  hasScroll
}: PropsWithChildren<TableWithHeadersProps>) => {
  return (
    <div className="flow-root text-xs md:text-sm">
      <div className="overflow-x-auto shadow-lg">
        <div className="inline-block min-w-full rounded-t-3xl  overflow-hidden align-middle">
          <table className="w-full">
            <thead className={clsx('bg-gray-200')}>
              <tr
                className={clsx(
                  hasScroll && 'table w-full table-fixed',
                  isLoading ? 'text-black animate-pulse' : 'text-white'
                )}
              >
                <th
                  className={clsx(
                    'py-3.5 text-sm px-6 bg-black font-extralight tracking-wider w-10'
                  )}
                ></th>
                {headers.map((h, ind) => (
                  <th
                    key={`${h}-${ind}`}
                    scope="col"
                    className={clsx(
                      'py-3.5 pr-4 text-sm bg-black font-extralight tracking-wider',
                      // 'py-2 md:py-3.5 text-sm px-4 bg-black font-extralight tracking-wider',
                      h.align,
                      // isLoading ? 'text-black animate-pulse' : 'text-white',
                      hasScroll && 'sticky top-0'
                    )}
                  >
                    {h.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody
              className={clsx(
                'bg-white ',
                hasScroll && 'h-80 overflow-y-scroll block w-full'
              )}
            >
              {children}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
