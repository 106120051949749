import clsx from 'clsx';
import { IValidator } from '../model/validatorTypes';
import { LinkWrap, VALIDATORS_ROUTE, hideHashPart } from '../../../shared';
import ValidatorLogo from './ValidatorLogo';

type ValidatorMonikerProps = {
  validator: IValidator;
  truncate?: boolean;
  className?: string;
};

export const ValidatorMoniker = ({
  validator,
  truncate,
  className
}: ValidatorMonikerProps) => {
  return (
    <LinkWrap
      className={className || ''}
      to={`${VALIDATORS_ROUTE}/${validator.address}`}
    >
      <div className="flex space-x-2.5 items-center text-sm md:text-base">
        <ValidatorLogo logo={validator.logo} />
        <div className={clsx('', truncate && 'truncate')}>
          {validator.moniker || hideHashPart(validator.address)}
        </div>
      </div>
    </LinkWrap>
  );
};
