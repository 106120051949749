import { useEffect } from 'react';
import { TTransaction } from '../../../entities/transaction';
import {
  ITransaction,
  TableRow,
  TableWithHeaders,
  createEmptyArray,
  SkeletonRow,
  Nothing
} from '../../../shared';
import { TransactionRow } from './TransactionRow';
import { TableSearch, useTableSearch } from '../../table-search';

type TransactionsProps = {
  transactions: TTransaction[];
  isLoading?: boolean;
};

const headers = [
  { name: 'Block', align: 'text-left' },
  { name: 'Type', align: 'text-left' },
  { name: 'Tx-hash', align: 'text-left' }
];

const placeholders = createEmptyArray(10);

export const TransactionsTableWithSearch = ({
  transactions,
  isLoading
}: TransactionsProps) => {
  const {
    searchInput,
    searchIsOpened,
    handleChangeSearchInput,
    handleOpenSearch,
    filteredData
  } = useTableSearch(transactions, (t) => {
    if (typeof t === 'object' && 'hash_id' in t) {
      return (
        t.hash_id.toString().includes(searchInput.toLowerCase()) ||
        t.height.toString().includes(searchInput.toLowerCase())
      );
    } else {
      return false;
    }
  });

  return (
    <div className="relative">
      <TableSearch
        value={searchInput}
        onChange={handleChangeSearchInput}
        isOpen={searchIsOpened}
        onToggle={handleOpenSearch}
        placeholder="tx hash or block height"
      />
      <TableWithHeaders headers={headers}>
        {filteredData &&
          (filteredData as TTransaction[]).map((t) => (
            <TableRow key={t.hash_id}>
              <TransactionRow {...t} />
            </TableRow>
          ))}
      </TableWithHeaders>
      {filteredData && filteredData.length === 0 && <Nothing />}
    </div>
  );
};
