import { Navigate, createBrowserRouter } from 'react-router-dom';

import {
  ArrowsRightLeftIcon,
  CubeIcon,
  CubeTransparentIcon,
  HomeIcon,
  ListBulletIcon,
  UsersIcon
} from '@heroicons/react/24/solid';
import { FaVoteYea } from 'react-icons/fa';
import { BsLayoutSidebarInsetReverse } from 'react-icons/bs';

import { App } from '.';
import { Dashboard } from '../pages/dashboard';
import { Validators } from '../pages/validators';
import { Blocks } from '../pages/blocks';
import { Transactions } from '../pages/transactions';
import { Parameters } from '../pages/parameters';
import { ValidatorPage } from '../pages/validator';
import { BlockPage } from '../pages/block';
import { TransactionPage } from '../pages/transaction';
import {
  ACCOUNT_ROUTE,
  BLOCKS_ROUTE,
  DASHBOARD_ROUTE,
  PARAMETERS_ROUTE,
  TRANSACTIONS_ROUTE,
  VALIDATORS_ROUTE,
  GOVERNANCE_ROUTE,
  UPTIME_ROUTE,
  PROPOSALS_URL,
  PROPOSAL_ROUTE,
  ErrorBoundaryWrapper
} from '../shared';
import { AccountPage } from '../pages/account';
import { Governance } from '../pages/governance';
import { UptimePage } from '../pages/uptime';
import { ProposalPage } from '../pages/proposal';

export const navRoutes = [
  {
    element: (
      <ErrorBoundaryWrapper>
        <Dashboard />
      </ErrorBoundaryWrapper>
    ),
    path: DASHBOARD_ROUTE,
    title: 'Dashboard',
    icon: HomeIcon
  },
  {
    element: (
      <ErrorBoundaryWrapper>
        <Validators />
      </ErrorBoundaryWrapper>
    ),
    path: VALIDATORS_ROUTE,
    title: 'Validators',
    icon: UsersIcon
  },
  {
    element: (
      <ErrorBoundaryWrapper>
        <UptimePage />
      </ErrorBoundaryWrapper>
    ),
    path: UPTIME_ROUTE,
    title: 'Uptime',
    icon: BsLayoutSidebarInsetReverse
  },
  {
    element: (
      <ErrorBoundaryWrapper>
        <Blocks />
      </ErrorBoundaryWrapper>
    ),
    path: BLOCKS_ROUTE,
    title: 'Blocks',
    icon: CubeIcon
  },
  {
    element: (
      <ErrorBoundaryWrapper>
        <Transactions />
      </ErrorBoundaryWrapper>
    ),
    path: TRANSACTIONS_ROUTE,
    title: 'Transactions',
    icon: ArrowsRightLeftIcon
  },
  {
    element: (
      <ErrorBoundaryWrapper>
        <Governance />
      </ErrorBoundaryWrapper>
    ),
    path: GOVERNANCE_ROUTE,
    title: 'Governance',
    icon: FaVoteYea
  },
  {
    element: (
      <ErrorBoundaryWrapper>
        <Parameters />
      </ErrorBoundaryWrapper>
    ),
    path: PARAMETERS_ROUTE,
    title: 'Parameters',
    icon: ListBulletIcon
  }
];

export const router = createBrowserRouter([
  {
    element: <App />,
    path: '/',
    children: [
      ...navRoutes,
      {
        element: (
          <ErrorBoundaryWrapper>
            <ValidatorPage />
          </ErrorBoundaryWrapper>
        ),
        path: VALIDATORS_ROUTE + '/:address'
      },

      {
        element: (
          <ErrorBoundaryWrapper>
            <BlockPage />
          </ErrorBoundaryWrapper>
        ),
        path: BLOCKS_ROUTE + '/:height'
      },
      {
        element: (
          <ErrorBoundaryWrapper>
            <TransactionPage />
          </ErrorBoundaryWrapper>
        ),
        path: TRANSACTIONS_ROUTE + '/:hash'
      },
      {
        element: (
          <ErrorBoundaryWrapper>
            <AccountPage />
          </ErrorBoundaryWrapper>
        ),
        path: ACCOUNT_ROUTE + '/:address'
      },
      {
        element: (
          <ErrorBoundaryWrapper>
            <ProposalPage />
          </ErrorBoundaryWrapper>
        ),
        path: PROPOSAL_ROUTE + '/:id'
      },
      {
        element: <Navigate to={'/'} />,
        path: '*'
      }
    ]
  }
]);
