import { useEffect } from 'react';
import TopValidators from '../../../widgets/list-validators/ui/TopValidators';
import { Overview } from './Overview';
import { LatestBlocks } from '../../../features/list-blocks';
import { validatorsStore } from '../../../entities/validator';
import { ErrorBoundaryWrapper } from '../../../shared';

export const Dashboard = () => {
  const { getValidators } = validatorsStore();

  useEffect(() => {
    getValidators();
  }, []);

  return (
    <>
      <Overview />
      <TopValidators />
      <LatestBlocks />
    </>
  );
};
