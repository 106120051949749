import clsx from 'clsx';
import grayTilesImg from '../../ui/img/gray-tiles.png';

export const Nothing = ({
  noShadow,
  isCentered = true
}: {
  noShadow?: boolean;
  isCentered?: boolean;
}) => {
  return (
    <div
      className={clsx(
        'text-center py-4 uppercase relative overflow-hidden bg-neutral-100 h-full text-xl',
        !noShadow ? 'shadow-lg' : 'rounded-lg'
      )}
    >
      <img className="absolute w-full h-full object-cover" src={grayTilesImg} />
      <div
        className={clsx(
          'relative z-30 font-bold h-full flex justify-center',
          isCentered ? 'items-center' : 'items-start mt-20'
        )}
      >
        Nothing here yet...
      </div>
    </div>
  );
};
