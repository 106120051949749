import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import NamadaTiles from '../../../shared/ui/img/namada-tiles-2.png';
import { NamadaLogo } from './NamadaLogo';

import {
  Card,
  Header,
  AddressBlock,
  formatNumber,
  activeClasses,
  handleCopyValue
} from '../../../shared';
import { PageHeader } from '../../../widgets/page-header';
import { accountStore } from '../../../entities/account';
import { TokensTable } from '../../../features/list-tokens';
import { TransactionsTable } from '../../../features/list-transactions';
import clsx from 'clsx';
import { Pagination, usePagination } from '../../../features/pagination';
import { Delegations } from '../../../widgets/delegations';
import { AccVotesTable } from '../../../widgets/list-votes';
import { voteStore } from '../../../entities/vote';
import { ValidatorMoniker, validatorsStore } from '../../../entities/validator';
import { FaRegCopy } from 'react-icons/fa';
import { AccAddresses } from './AccAddresses';

export const AccountPage = () => {
  const { address } = useParams();
  const {
    balances,
    // publicKeys,
    accTransactions,
    accTransactionsLoading,
    accTransactionsPagination,
    getAccountData,
    getAccTransactions,
    getDelegations,
    accountLoading,
    delegations,
    delegationsLoading
  } = accountStore();

  const { accVotes, getVotes, accVotesLoading } = voteStore();
  const { findValidatorByAddress } = validatorsStore();

  const handleGetAccData = (address: string) => {
    getAccountData(address);
    getAccTransactions(address, 1);
    getDelegations(address);
    getVotes(address);
  };

  useEffect(() => {
    handleGetAccData(address as string);
  }, [address]);

  const pagination = usePagination(
    accTransactionsPagination?.totalPages as number,
    (page) => getAccTransactions(address as string, page)
  );

  return (
    <div>
      <PageHeader isHiding>Account details</PageHeader>
      <div className="flex flex-col sm:flex-row gap-4 xl:gap-6 mb-10">
        <Card
          color="black"
          className={clsx(
            'sm:w-4/12 self-stretch',
            accountLoading && 'animate-pulse'
          )}
        >
          <div className="flex flex-col justify-between h-full">
            <div>
              <div className="mb-4 mt-3">
                <NamadaLogo />
              </div>
              <div className="text-center uppercase text-xl sm:text-2xl lg:text-3xl xl:text-4xl mb-4">
                NAAN
              </div>
              {findValidatorByAddress(address as string) && (
                <div className="rounded-full truncate bg-primary px-3 py-1 text-black flex items-center justify-center w-min mx-auto mb-4">
                  <ValidatorMoniker
                    validator={findValidatorByAddress(address as string)}
                  />
                </div>
              )}
            </div>
            <div className="text-center sm:text-xl lg:text-2xl">0 USD</div>
          </div>
        </Card>

        <div className="flex flex-col self-stretch gap-4 xl:gap-6 sm:w-8/12">
          {/* Balance card */}
          <Card
            color="yellow"
            className={clsx('self-stretch', accountLoading && 'animate-pulse')}
            padding="p-1"
          >
            <div className="flex items-center justify-center  sm:justify-between h-full">
              <div className="flex flex-col justify-between h-full sm:w-7/12 p-4 xl:p-6">
                <div
                  className={clsx(
                    'text-xl xl:text-4xl font-medium uppercase whitespace-normal mb-6 text-center sm:text-left'
                  )}
                >
                  {accountLoading
                    ? 'XXXX'
                    : balances && Number(balances?.naan ?? 0).toFixed(2)}{' '}
                  NAAN
                </div>

                <div className="truncate uppercase text-center sm:text-left">
                  Balance
                </div>
              </div>

              {/* Tiles */}
              <div className="hidden sm:block sm:w-5/12 pr-4 xl:pr-6">
                <img
                  className="w-full h-full object-contain ml-2 -mt-1"
                  src={NamadaTiles}
                />
              </div>
            </div>
          </Card>

          <AccAddresses />
        </div>
      </div>

      {/* Tokens */}
      <div className="mb-10">
        <Header className="mb-4 xl:mb-6">Tokens</Header>
        <TokensTable balances={{ ...balances }} isLoading={accountLoading} />
      </div>

      {/* Transactions */}
      <div className="mb-10">
        <Header className="mb-4 xl:mb-6">Transactions</Header>
        <>
          <TransactionsTable
            transactions={accTransactions || []}
            isLoading={accTransactionsLoading}
          />
          {pagination.totalPages > 1 && <Pagination {...pagination} />}
        </>
      </div>

      {/* Delegations */}
      <div className="mb-10">
        <Header className="mb-4 xl:mb-6">Delegations</Header>
        <Delegations delegations={delegations} isLoading={delegationsLoading} />
      </div>

      {/* Votes */}
      <div className="mb-10">
        <Header className="mb-4 xl:mb-6">Votes</Header>
        <AccVotesTable votes={accVotes} isLoading={accVotesLoading} />
      </div>
    </div>
  );
};
