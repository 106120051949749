import { links } from '../model';
import { Socialitem } from './SocialItem';

export const Social = () => {
  return (
    <div className="flex items-center gap-1 sm:gap-2 lg:gap-1 2xl:gap-2 shrink-0">
      {links.map((l) => (
        <Socialitem key={l.link} {...l} />
      ))}
    </div>
  );
};
