import dayjs from 'dayjs';
import NamadaTiles from '../../../shared/ui/img/namada-tiles.png';

import { Card, DataWLoader, formatNumber } from '../../../shared';
import { blockStore } from '../../../entities/block';

import { ActiveValidators } from '../../../widgets/active-validators';
import { PageHeader } from '../../../widgets/page-header';
import { Tokenomics } from '../../../widgets/tokenomics';
import { Epoch } from '../../../widgets/epoch';
import { BlockTime } from '../../../widgets/block-time';

export const Overview = () => {
  const {
    latestBlockHeight,
    latestBlockTime,
    networkId,
    statusError,
    statusLoading,
    sortedBlocks,
    blocksLoading
  } = blockStore();

  return (
    <div className="mb-10">
      <PageHeader>Overview</PageHeader>
      <div className="flex flex-col md:flex-row w-full items-center gap-4 xl:gap-6 mb-4 xl:mb-6">
        <Card
          color="yellow"
          className="hidden md:block self-stretch md:w-4/12"
          padding="p-4 xl:px-4"
        >
          <img
            className="w-full h-full object-contain ml-2 -mt-1"
            src={NamadaTiles}
          />
        </Card>
        <Card className="self-stretch md:w-5/12">
          <DataWLoader
            name="Network Id"
            data={networkId ?? null}
            isLoading={statusLoading}
            error={statusError}
          />
        </Card>
        <Card className="self-stretch md:w-3/12">
          {/* <DataWLoader
            name="Block time"
            data={dayjs(latestBlockTime).diff(sortedBlocks[1]?.time)}
            isLoading={blocksLoading}
            error={statusError}
          /> */}
          <BlockTime />
        </Card>
      </div>

      <div className="flex flex-col md:flex-row items-center gap-4 xl:gap-6">
        <div className="md:w-7/12 flex flex-col self-stretch gap-4 xl:gap-6">
          <div className="flex flex-col md:flex-row items-center gap-4 xl:gap-6 h-1/2">
            <Card className="md:w-7/12 lg:w-2/3 self-stretch">
              <Epoch />
            </Card>
            <Card className="md:w-5/12 lg:w-1/3 self-stretch">
              <DataWLoader
                name="Block height"
                data={
                  formatNumber(latestBlockHeight?.toString() ?? '-') ?? null
                }
                isLoading={statusLoading}
                error={statusError}
              />
            </Card>
          </div>
          <Card className="h-1/2">
            <ActiveValidators />
          </Card>
        </div>
        <Card className="md:w-5/12 self-stretch flex flex-col justify-between">
          <Tokenomics />
        </Card>
      </div>
    </div>
  );
};
