import { useEffect, useState } from 'react';
import { LayoutWithLoaderAndError, activeClasses } from '../../../shared';

import { validatorsStore } from '../../../entities/validator';

import { ValidatorsTable } from '../../../widgets/list-validators';
import { PageHeader } from '../../../widgets/page-header';
import { useTabs, TabSwitcher } from '../../../features/tab-switcher';
import clsx from 'clsx';

const tabs = ['Active', 'Inactive'];

export const Validators = () => {
  const {
    activeValidators,
    inactiveValidators,
    allValidators,
    getValidators,
    validatorsError,
    validatorsLoading
  } = validatorsStore();
  const { currentTab, handleChangeTab } = useTabs(tabs);

  useEffect(() => {
    if (allValidators.length === 0) {
      getValidators();
    }
  }, [allValidators]);

  return (
    <LayoutWithLoaderAndError
      isLoading={validatorsLoading}
      error={validatorsError}
    >
      <div>
        <PageHeader isHiding>Validators</PageHeader>
        <div className="flex items-center justify-between mb-4">
          <TabSwitcher
            onChange={handleChangeTab}
            currentTab={currentTab}
            tabs={tabs}
          />
          <a
            href="https://github.com/alxnode/valopers/blob/main/namada/validators.json"
            target="_blank"
            rel="noreferrer"
            className={clsx(
              'bg-black text-primary hover:bg-primary hover:text-black px-4 py-1 rounded-full shadow text-sm w-max',
              activeClasses
            )}
          >
            Name service
          </a>
        </div>
        {currentTab === 'Active' && (
          <ValidatorsTable validators={activeValidators} />
        )}
        {currentTab === 'Inactive' && (
          <ValidatorsTable validators={inactiveValidators} />
        )}
      </div>
    </LayoutWithLoaderAndError>
  );
};
