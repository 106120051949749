import { useParams } from 'react-router';
import { useEffect } from 'react';
import {
  BLOCKS_ROUTE,
  Card,
  Data,
  DataCardHeader,
  Header,
  LayoutWithLoaderAndError,
  LinkWrap,
  TRANSACTIONS_ROUTE,
  activeClasses,
  handleCopyValue
} from '../../../shared';
import { validatorsStore, ValidatorMoniker } from '../../../entities/validator';
import { transactionStore } from '../../../entities/transaction';
import { PageHeader } from '../../../widgets/page-header';
import { FaRegCopy } from 'react-icons/fa';
import clsx from 'clsx';

import { JsonView, allExpanded, darkStyles } from 'react-json-view-lite';
import { DataWChildren } from '../../../shared/ui/DataWChildren';

export const TransactionPage = () => {
  const { hash } = useParams();

  const { allValidators, getValidators, findValidatorByAddress } =
    validatorsStore();

  const {
    transaction,
    getTransactionData,
    transactionsLoading,
    transactionsError
  } = transactionStore();

  useEffect(() => {
    if (allValidators.length === 0) {
      getValidators();
    }
    getTransactionData(hash as string);
  }, [hash]);

  return (
    <LayoutWithLoaderAndError
      isLoading={transactionsLoading}
      error={transactionsError}
    >
      {transaction && (
        <>
          <div>
            <PageHeader>Transaction details</PageHeader>

            <div className="mb-4 xl:mb-6 grid sm:grid-cols-2 md:grid-cols-4 gap-4 xl:gap-6 items-center w-full">
              <Card>
                <DataWChildren title="Block height">
                  <LinkWrap
                    className="sm:text-lg xl:text-xl"
                    to={`${BLOCKS_ROUTE}/${transaction.header_height}`}
                  >
                    {transaction.header_height.toString()}
                  </LinkWrap>
                </DataWChildren>
              </Card>
              <Card className="self-stretch">
                <DataWChildren title="Status">
                  <span
                    className={clsx(
                      'sm:text-lg xl:text-xl truncate',
                      transaction.return_code === 0 ||
                        transaction.return_code === null
                        ? 'text-green-600'
                        : 'text-red-500'
                    )}
                  >
                    {transaction.return_code === 0 ||
                    transaction.return_code === null
                      ? 'Success'
                      : 'Fail'}
                  </span>
                </DataWChildren>
              </Card>
              <Card className="self-stretch">
                <Data
                  title="Fee"
                  value={
                    transaction.fee_amount_per_gas_unit
                      ? `${transaction.fee_amount_per_gas_unit} NAAN`
                      : '-'
                  }
                />
              </Card>
              <Card className="self-stretch">
                <Data title="Type" value={transaction.tx_type} />
              </Card>
            </div>

            <Card className="mb-4 xl:mb-6">
              <div className={clsx(transaction.wrapper_id && 'mb-6')}>
                <div className="mb-4 flex items-center space-x-4 uppercase">
                  <div className="font-medium text-lg sm:text-xl xl:text-2xl">
                    Tx hash
                  </div>
                  <button onClick={() => handleCopyValue(hash as string)}>
                    <FaRegCopy className={clsx('sm:text-xl', activeClasses)} />
                  </button>
                </div>

                <div className="truncate sm:text-lg xl:text-xl uppercase">
                  {hash}
                </div>
              </div>

              {transaction.wrapper_id && (
                <div>
                  <div className="mb-4 flex items-center space-x-4 uppercase">
                    <div className="font-medium text-xl xl:text-2xl">
                      Wrapper id
                    </div>
                    <button
                      onClick={() =>
                        handleCopyValue(transaction.wrapper_id as string)
                      }
                    >
                      <FaRegCopy className={clsx('text-xl', activeClasses)} />
                    </button>
                  </div>
                  <div className="truncate text-lg xl:text-xl">
                    <LinkWrap
                      to={TRANSACTIONS_ROUTE + '/' + transaction.wrapper_id}
                    >
                      {transaction.wrapper_id}
                    </LinkWrap>
                  </div>
                </div>
              )}
            </Card>
            <Card>
              <div className="font-medium text-xl xl:text-2xl uppercase mb-6">
                Raw data
              </div>
              <JsonView
                data={transaction}
                shouldExpandNode={allExpanded}
                style={{
                  ...darkStyles,
                  container:
                    'py-4 bg-black text-white font-normal px-2 overflow-y-auto',
                  label: 'mr-2',
                  punctuation: 'font-normal',
                  numberValue: 'text-green-500',
                  stringValue: 'text-primary',
                  nullValue: 'text-red-500',
                  undefinedValue: 'text-neutral-400'
                }}
              />
            </Card>
          </div>
          <div></div>
        </>
      )}
    </LayoutWithLoaderAndError>
  );
};
