import clsx from 'clsx';
import { IValidator, validatorsStore } from '../../../entities/validator';
import { useEffect, useState } from 'react';
import { IBlock, blockStore } from '../../../entities/block';
import { UptimeBlocks, UptimeValidator, initialShrinkedBlocks } from '../model';

type UptimeProps = UptimeValidator & {
  ind: number;
  isScaled: boolean;
  blockIndex: number;
};

export const ValUptime = (validator: UptimeProps) => {
  const [blocks, setBlocks] = useState(initialShrinkedBlocks);
  const { blocksLoading } = blockStore();
  const { uptimeIndex } = validatorsStore();

  return (
    <div className={clsx(validator.isScaled && 'xl:text-[9px]')}>
      <div
        className={clsx(
          'flex items-center',
          validator.isScaled
            ? 'mb-3 xl:text-[9px] 2xl:text-[10px] xl:mb-px'
            : 'mb-3'
        )}
      >
        <div
          className={clsx(
            'text-white rounded-full flex items-center justify-center shrink-0 h-7 text-sm w-7 bg-black mr-2',
            validator.isScaled && 'xl:hidden'
          )}
        >
          {validator.ind + 1}
        </div>
        <div className="truncate">{validator.moniker || validator.address}</div>
      </div>
      <div className="grid grid-cols-[repeat(50,_minmax(0,_1fr))] gap-px">
        {validator.blocks &&
          validator.blocks.map((b, ind) => (
            <div
              key={`${validator.address}-block-${ind}`}
              title={b.height.toString()}
              className={clsx(
                'rounded',
                validator.isScaled ? 'h-9 xl:h-1.5 2xl:h-2' : 'h-9',
                ind > uptimeIndex && '!bg-black',
                blocksLoading && 'animate-pulse',
                b.status === 1 && 'bg-energy-green',
                b.status === 0 && 'bg-red-500',
                b.status === -1 && 'bg-black',
                b.status === 2 && 'bg-green-600'
              )}
            ></div>
          ))}
      </div>
    </div>
  );
};
