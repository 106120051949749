import { logger } from '../../../shared';
import { VotesNumber } from '../model';

export const countVotesPercentage = ({ yay, nay, abstain }: VotesNumber) => {
  const total = Math.floor(yay + nay + abstain);

  const percentage = {
    yay: getPercent(total, yay),
    nay: getPercent(total, nay),
    abstain: getPercent(total, abstain)
  };

  return percentage;
};

const getPercent = (total: number, value: number) => {
  return (value * 100) / total;
};

export const isGrace = (current: number, end: number, grace: number) => {
  return current >= end && current < grace;
};

export const isVoting = (current: number, start: number, end: number) => {
  return current >= start && current < end;
};
