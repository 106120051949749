import { StoreApi, create } from 'zustand';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { IParams } from './parameterTypes';
import { parameterApi } from '../api';
import { logger } from '../../../shared/';

type Store = {
  parameters: IParams | null;
  parametersLoading: boolean;
  parametersError: string | null;
};

type Actions = {
  getParameters: () => Promise<void>;
};

type ParameterStore = Store & Actions;

export const parameterStore = create<ParameterStore>(
  (
    set: StoreApi<ParameterStore>['setState'],
    get: StoreApi<ParameterStore>['getState']
  ) => ({
    parameters: null,
    parametersLoading: true,
    parametersError: null,
    getParameters: async () => {
      set({
        parametersError: null,
        parametersLoading: true
      });
      try {
        const data = await parameterApi.getParameters();

        set({ parameters: data });
      } catch (error) {
        if (error instanceof AxiosError) {
          console.error(error);
          const message =
            'Something went wrong when we tried to get chain parameters';
          set({
            parametersError: message
          });
          toast.error(message);
        }
      } finally {
        set({ parametersLoading: false });
      }
    }
  })
);
