import { useEffect, useState } from 'react';
import { IBlock, blockStore } from '../../../entities/block';
import { logger } from '../../../shared';
import dayjs from 'dayjs';

export const useTimeDiff = () => {
  const { latestBlockHeight, latestBlockTime, getBlocks, blocks } =
    blockStore();

  const [timeDiff, setTimeDiff] = useState<number | null>(null);

  const handleGetTimeDiff = async (latestBlockHeight: number) => {
    let prevBlock: IBlock | undefined;

    if (blocks.length === 0) {
      const prevBlocks = await getBlocks({
        startHeight: latestBlockHeight - 1,
        num: 1
      });

      if (prevBlocks) {
        prevBlock = prevBlocks[0];
      }
    } else {
      prevBlock = blocks.find((b) => b.block_height === latestBlockHeight - 1);
    }

    if (prevBlock) {
      const time = prevBlock.time;

      const diff = dayjs(latestBlockTime).diff(time);

      setTimeDiff(diff / 1000);
    }
  };

  useEffect(() => {
    if (latestBlockHeight) {
      handleGetTimeDiff(latestBlockHeight);
    }
  }, [latestBlockHeight]);

  return { timeDiff };
};
