import { useEffect } from 'react';
import { blockStore } from '../../../entities/block';
import { Header, Loader } from '../../../shared';
import { BlocksTable } from './BlocksTable';

export const LatestBlocks = () => {
  const { latestBlocks, blocksLoading, blocksError } = blockStore();

  return (
    <div className="mt-8 mb-10">
      <Header className="mb-4 xl:mb-8">Latest blocks</Header>
      {blocksLoading ? (
        <div className="flex space-x-5 justify-center">
          <Loader size={50} />
        </div>
      ) : (
        <BlocksTable blocks={latestBlocks} />
      )}
    </div>
  );
};
