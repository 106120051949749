import clsx from 'clsx';

type TwoTabsProps = {
  onChange: (tab: string) => void;
  currentTab: string;
  tabs: string[];
};

export const TabSwitcher = ({ onChange, currentTab, tabs }: TwoTabsProps) => {
  return (
    <div className="flex items-center text-sm relative bg-primary w-max rounded-full">
      {tabs.map((t, ind) => (
        <div
          key={t}
          onClick={() => onChange(t)}
          className={clsx(
            'py-1 px-3 relative rounded-full',
            currentTab === t
              ? 'bg-black text-primary cursor-default z-20'
              : ' bg-primary text-black cursor-pointer z-10 hover:z-20 hover:bg-black hover:text-primary transition-all duration-300'
          )}
        >
          {t}
        </div>
      ))}
    </div>
  );
};
