import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

type LinkWrapProps = { to: string; className?: string };

export const LinkWrap = ({
  children,
  to,
  className
}: PropsWithChildren<LinkWrapProps>) => {
  return (
    <Link
      to={to}
      className={clsx(
        'underline lg:no-underline hover:lg:underline',
        className
      )}
    >
      {children}
    </Link>
  );
};
