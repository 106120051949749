import { PieChart } from 'react-minimal-pie-chart';
import { DataCardHeader, SUPPLY } from '../../../shared';
import { validatorsStore } from '../../../entities/validator';

export const Tokenomics = () => {
  const { totalBonded } = validatorsStore();

  return (
    <>
      <DataCardHeader>Tokenomics</DataCardHeader>
      <div className="flex items-end justify-between gap-4">
        <div className="w-1/3 flex flex-col justify-end self-stretch">
          <div className="flex items-center gap-2 mb-4 lg:mb-10">
            <div className="shrink-0 w-2 self-stretch bg-black"></div>
            <div>
              <div className="text-xl lg:text-2xl mb-1 lg:mb-2">
                {(Number(SUPPLY) / 1000000000).toFixed(2)} B
              </div>
              <div className="text-lg lg:text-xl">Supply</div>
              <div>100%</div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="shrink-0 w-2 self-stretch bg-primary"></div>
            <div>
              <div className="text-xl lg:text-2xl mb-1 lg:mb-2">
                {(Number(totalBonded) / 1000000000).toFixed(2)} B
              </div>
              <div className="text-lg lg:text-xl">Bonded</div>
              <div>{((Number(totalBonded) * 100) / SUPPLY).toFixed(2)}%</div>
            </div>
          </div>
        </div>
        <PieChart
          className="w-2/3"
          data={[
            {
              value: (Number(totalBonded) * 100) / SUPPLY,
              color: '#FFFF00'
            },
            { value: 100, color: '#000000' }
          ]}
        />
      </div>
    </>
  );
};
