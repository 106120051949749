import { validatorsStore } from '../../../entities/validator';
import {
  TableWithHeaders,
  TableRow,
  IDelegation,
  SkeletonRow,
  Nothing,
  IDelegations,
  logger
} from '../../../shared';
import { TableSearch, useTableSearch } from '../../table-search';
import { DelegationsRow } from './DelegationsRow';

type Props = {
  delegations: IDelegation[] | undefined;
  isLoading: boolean;
  isDelegator?: boolean;
};

export const DelegationsTable = ({
  delegations,
  isLoading,
  isDelegator
}: Props) => {
  const { findValidatorByAddress } = validatorsStore();

  const {
    searchInput,
    searchIsOpened,
    handleChangeSearchInput,
    handleOpenSearch,
    filteredData
  } = useTableSearch(delegations, (d) => {
    if (typeof d === 'object' && 'amount' in d) {
      const moniker = findValidatorByAddress(d.address)?.moniker;
      if (moniker) {
        return (
          d.address.toLowerCase().includes(searchInput.toLowerCase()) ||
          moniker.toLowerCase().includes(searchInput.toLowerCase())
        );
      } else {
        return d.address.toLowerCase().includes(searchInput.toLowerCase());
      }
    } else {
      return false;
    }
  });

  return (
    <div className="relative">
      <TableSearch
        value={searchInput}
        onChange={handleChangeSearchInput}
        isOpen={searchIsOpened}
        onToggle={handleOpenSearch}
        placeholder="address or moniker"
      />
      <TableWithHeaders
        headers={[
          { name: isDelegator ? 'Delegator' : 'Validator', align: 'text-left' },
          { name: 'Amount', align: 'text-left' }
        ]}
        isLoading={isLoading}
        hasScroll={true}
      >
        {isLoading ? (
          <TableRow>
            <SkeletonRow cellsCount={2} />
          </TableRow>
        ) : (
          filteredData &&
          (filteredData as IDelegation[]).map((d) => (
            <TableRow hasScroll={true} key={d.address}>
              <DelegationsRow {...d} />
            </TableRow>
          ))
        )}
        {!isLoading &&
          (!filteredData || (filteredData && filteredData.length === 0)) && (
            <Nothing />
          )}
      </TableWithHeaders>
    </div>
  );
};
