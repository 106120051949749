import clsx from 'clsx';

export const ProposalSkeleton = () => {
  return (
    <div className="animate-pulse">
      <div className="flex justify-between items-center p-2">
        <div className="flex items-center text-lg mb-1">
          <div className="mr-2 font-light">#XXX</div>
          <div className={clsx('uppercase font-light')}>RESULT</div>
        </div>
        <div className="text-xs">Start Epoch / End Epoch</div>
      </div>

      <div className="flex justify-between items-center text-xs mb-2.5">
        <div className="truncate mr-2">proposal title</div>
        <div>
          {0}/{0}
        </div>
      </div>
      <div
        className="w-full h-5 bg-neutral-200 flex items-center overflow-hidden rounded-full shadow-[0px_1px_1px_rgba(50,_50,_50,_10%)]"
        title="no votes"
      ></div>
    </div>
  );
};
