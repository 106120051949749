import { VALOPERS_URL, httpService } from '../../../shared';
import { IProposalVotes, IVote } from '../model';

export const voteApi = {
  getVotes: async (address: string): Promise<IVote[]> => {
    const data = await httpService.get(
      VALOPERS_URL + '/getAllVotes/' + address
    );

    return data;
  },
  getProposalVotes: async (id: string): Promise<IProposalVotes> => {
    const data = await httpService.get(
      VALOPERS_URL + '/getProposalVotes/' + id
    );

    return data;
  }
};
