import clsx from 'clsx';
import { PropsWithChildren } from 'react';

type TableRowProps = {
  isLoading?: boolean;
  hasScroll?: boolean;
  withSearch?: boolean;
};

export const TableRow = ({
  children,
  isLoading,
  hasScroll
}: PropsWithChildren<TableRowProps>) => {
  return (
    <tr
      className={clsx(
        'even:bg-neutral-100 tracking-wider relative ',
        isLoading && 'animate-pulse',
        hasScroll && 'table-fixed table w-full'
      )}
    >
      <td className="w-10"></td>
      {children}
    </tr>
  );
};
