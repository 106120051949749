import 'react-toastify/dist/ReactToastify.min.css';
import 'react-json-view-lite/dist/index.css';
import { Outlet } from 'react-router';
import { ToastContainer } from 'react-toastify';

import { parameterStore } from '../entities/parameter';
import { useEffect, useState } from 'react';
import { blockStore } from '../entities/block';
import { validatorsStore } from '../entities/validator';
import { Navigation } from '../widgets/navigation';
import clsx from 'clsx';

export const App = () => {
  const { getParameters } = parameterStore();
  const { getEpoch } = blockStore();
  const { getValidators } = validatorsStore();

  const [isExtended, setIsExtended] = useState(false);

  const handleExtend = () => {
    setIsExtended((extended) => !extended);
  };

  useEffect(() => {
    getValidators();
    getParameters();
    getEpoch();
  }, []);

  return (
    <>
      <div className="tracking-wider">
        <Navigation isExtended={isExtended} handleExtend={handleExtend} />
        <main
          className={clsx(
            'py-4 lg:py-7 xl:pt-11',
            isExtended ? 'lg:pl-64 xl:pl-72' : 'lg:pl-28'
          )}
        >
          <div className="px-4 sm:px-6 xl:px-8">
            <Outlet />
          </div>
        </main>
      </div>
      <ToastContainer />
    </>
  );
};
