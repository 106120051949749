import { IBlock } from '../../../entities/block';
import { IValidator } from '../../../entities/validator';
import { BLOCKSNUM, logger } from '../../../shared';

type UptimeStatus = 'active' | 'inactive' | 'proposer' | 'loading';

export type BlockByUptime = {
  height: number;
  status: UptimeStatus;
};

export const blocksMock: BlockByUptime[] = [];

for (let i = 0; i < BLOCKSNUM; i++) {
  blocksMock.push({ height: 0, status: 'loading' });
}

export const getUptimeByBlocks = (blocks: IBlock[], validator: IValidator) => {
  const blocksByUptime: BlockByUptime[] = blocks.map((block) => {
    if (block.proposer_address === validator.tendermint_key)
      return { height: block.block_height, status: 'proposer' };

    if (
      block.signatures.find(
        (signature) => signature.validator_address === validator.tendermint_key
      )
    ) {
      return { height: block.block_height, status: 'active' };
    } else {
      return { height: block.block_height, status: 'inactive' };
    }
  });

  return blocksByUptime;
};
