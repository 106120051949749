import { useEffect, useState } from 'react';
import { validatorsStore } from '../../../entities/validator';
import { Card, LayoutWithLoaderAndError, activeClasses } from '../../../shared';
import { PageHeader } from '../../../widgets/page-header';
import { UptimeDisplay } from '../../../widgets/validator-blocks-uptime';
import { blockStore } from '../../../entities/block';
import clsx from 'clsx';

export const UptimePage = () => {
  const { activeValidators, validatorsLoading, validatorsError } =
    validatorsStore();
  return (
    <LayoutWithLoaderAndError
      isLoading={validatorsLoading}
      error={validatorsError}
    >
      <PageHeader isHiding>Uptime</PageHeader>
      {activeValidators && <UptimeDisplay />}
    </LayoutWithLoaderAndError>
  );
};
