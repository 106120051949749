import {
  INDEXER_URL,
  RPC_URL,
  VALOPERS_URL,
  httpService
} from '../../../shared';

import {
  IBlockExtData,
  IBlocksPayload,
  IBlocksResult,
  IEpochResponse,
  IStatusResult
} from '../model';

export const blocksApi = {
  getStatus: async (): Promise<{
    result: IStatusResult;
  }> => await httpService.get(RPC_URL + '/status'),
  getEpoch: async (): Promise<IEpochResponse> =>
    await httpService.get(VALOPERS_URL + '/getEpoch'),
  getBlocks: async (payload: IBlocksPayload): Promise<IBlocksResult> =>
    await httpService.post(VALOPERS_URL + '/latestBlocks', payload),
  getLatestBlocks: async (payload: IBlocksPayload): Promise<IBlocksResult> =>
    await httpService.post(VALOPERS_URL + '/getBlocks', payload),
  getBlockData: async (blockHeight: number): Promise<IBlockExtData> =>
    await httpService.get(INDEXER_URL + '/block/height/' + blockHeight)
};
