import clsx from 'clsx';
import { LinkWrap, PROPOSAL_ROUTE, TableCell } from '../../../shared';
import { IVote, VoteType } from '../../../entities/vote';

type AccVoteRowProps = {
  proposal: string;
  vote: VoteType | null;
};

export const AccVoteRow = (vote: IVote) => {
  return (
    <>
      <TableCell
        className={clsx(
          'py-4 pr-4 text-left overflow-hidden truncate',
          vote.proposal_id === '-' && 'text-transparent'
        )}
      >
        <LinkWrap to={PROPOSAL_ROUTE + '/' + vote.proposal_id}>
          {' '}
          #{vote.proposal_id} {vote.title}
        </LinkWrap>
      </TableCell>
      <TableCell
        className={clsx(
          'py-4 text-left',
          vote.vote === null && 'text-transparent'
        )}
      >
        {vote.vote}
      </TableCell>
    </>
  );
};

// const Vote = (vote: VoteType) => {
//   switch (vote) {
//     case 'yay': return <div></div>
//     case 'nay':
//     case 'abstain':
//     case 'did_not_vote':

//   }
// }
