import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

import { NavItem } from './NavItem';
import { useLocation } from 'react-router';
import { Logo } from '../../../shared';
import { navRoutes } from '../../../app/Router';
import { getPageName } from '../lib/navigationLib';
import { Social } from '../../../features/social';
import clsx from 'clsx';

type Props = {
  isExtended: boolean;
  handleExtend: () => void;
};

export const Navigation = ({ isExtended, handleExtend }: Props) => {
  const { pathname } = useLocation();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/60" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full w-xs flex-1 ">
                <div className="flex flex-col gap-y-4 overflow-y-auto bg-black pb-2 px-8 ring-1 ring-white/10 pt-8">
                  <Logo />
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="-mx-2 space-y-4">
                      {navRoutes.map((item) => (
                        <NavItem
                          key={item.title}
                          {...item}
                          onClose={toggleSidebar}
                        />
                      ))}
                    </ul>
                  </nav>
                </div>

                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  {/* <div className="absolute left-52 top-0 flex w-16 justify-center pt-5"> */}
                  <div className="w-16 pt-5 pl-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="sticky top-0 z-40 flex justify-between items-center gap-x-6 bg-black px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <div className="flex items-center gap-3">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-400 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-base sm:text-xl font-semibold leading-6 text-white uppercase tracking-widest">
            {getPageName(pathname)}
          </div>
        </div>
        <Social />
      </div>

      {/* Desktop */}
      <div
        className={clsx(
          'hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col bg-black relative',
          isExtended ? 'lg:w-64 xl:w-72 px-6 xl:px-10' : 'lg:w-24 px-6'
        )}
      >
        <div className={clsx('mt-6 xl:mt-10')}>
          <Logo isExtended={isExtended} />
        </div>

        <nav
          className={clsx(
            'flex flex-1 flex-col gap-y-7 overflow-y-auto scrollbar-none mb-24'
          )}
        >
          {navRoutes.map((item) => (
            <NavItem key={item.title} {...item} isExtended={isExtended} />
          ))}
        </nav>
        <div
          className={clsx(
            'absolute bottom-0 left-0 w-full pb-6 flex items-center',
            isExtended ? 'px-6 xl:px-10' : 'px-6'
          )}
        >
          <div
            className={clsx(
              'text-primary size-[50px] flex items-center justify-center text-2xl cursor-pointer ',
              !isExtended && 'transform rotate-180'
            )}
            onClick={handleExtend}
          >
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              stroke="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="25"
                cy="25"
                r="24"
                transform="matrix(-1 0 0 1 50 0)"
                stroke="#FFFF00"
                strokeWidth="2"
              />
              <path
                d="M32 13L16 24.5L32 36"
                stroke="#FFFF00"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};
