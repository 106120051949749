import { Link } from 'react-router-dom';
import { TTransaction } from '../../../entities/transaction';
import {
  BLOCKS_ROUTE,
  ITransaction,
  LinkWrap,
  TRANSACTIONS_ROUTE,
  TableCell,
  formatNumber
} from '../../../shared';

export const TransactionRow = (transaction: TTransaction | ITransaction) => {
  return (
    <>
      <TableCell className="py-4 w-32">
        <LinkWrap
          to={`${BLOCKS_ROUTE}/${
            'height' in transaction
              ? transaction.height
              : transaction.header_height
          }`}
        >
          {'height' in transaction
            ? formatNumber(transaction.height)
            : formatNumber(transaction.header_height)}
        </LinkWrap>
      </TableCell>
      <TableCell className="py-4 w-32">{transaction.tx_type}</TableCell>
      <TableCell className="py-4 truncate">
        <LinkWrap
          to={`${TRANSACTIONS_ROUTE}/${
            'hash_id' in transaction ? transaction.hash_id : transaction.hash
          }`}
        >
          {'hash_id' in transaction ? transaction.hash_id : transaction.hash}
        </LinkWrap>
      </TableCell>
    </>
  );
};
