import { useState } from 'react';
import {
  AddressBlock,
  Card,
  activeClasses,
  handleCopyValue
} from '../../../shared';
import { accountStore } from '../../../entities/account';
import clsx from 'clsx';
import { useParams } from 'react-router';
import { FaRegCopy } from 'react-icons/fa';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

export const AccAddresses = () => {
  // const [publicKeys, _] = useState([
  //   'tpknam1qp6umw55umjy4radswxp822hg4dpmqzzh70937e8qslemr9rts335w53tjr',
  //   'tpknam1qp6umw55umjy4radswxp822hg4dpmqzzh70937e8qslemr9rts335w53tjr'
  //   // 'tpknam1qp6umw55umjy4radswxp822hg4dpmqzzh70937e8qslemr9rts335w53tjr',
  //   // 'tpknam1qp6umw55umjy4radswxp822hg4dpmqzzh70937e8qslemr9rts335w53tjr',
  //   // 'tpknam1qp6umw55umjy4radswxp822hg4dpmqzzh70937e8qslemr9rts335w53tjr'
  // ]);
  const { address } = useParams();

  const { publicKeys, accountLoading } = accountStore();

  const [isOpened, setIsOpened] = useState(false);

  const toggleOpen = () => {
    setIsOpened((prev) => !prev);
  };
  return (
    <div className="relative">
      <Card>
        <div className={clsx('mb-4 sm:mb-6')}>
          <AddressBlock title="Address" value={address as string} />
        </div>
        {accountLoading ? (
          <AddressBlock
            title="Public Key"
            isLoading={accountLoading}
            value="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
          />
        ) : (
          <>
            <div
              className={clsx(
                'mb-2 sm:mb-2 flex items-center space-x-4 uppercase',
                accountLoading && 'animate-pulse'
              )}
            >
              <div className="font-medium text-lg sm:text-xl xl:text-2xl">
                Public Key
              </div>
            </div>
            <div className="relative">
              <div className="text-base sm:text-lg xl:text-xl flex items-center w-10/12 xl:w-11/12">
                <div className="truncate">
                  {accountLoading
                    ? 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'
                    : publicKeys
                    ? publicKeys[0]
                    : '-'}
                  {/* {publicKeys[0]} */}
                </div>
                <button
                  onClick={() =>
                    publicKeys && [0] && handleCopyValue(publicKeys[0])
                  }
                >
                  <FaRegCopy
                    className={clsx(
                      'sm:text-lg xl:text-xl ml-2',
                      activeClasses
                    )}
                  />
                </button>
              </div>
              {publicKeys && publicKeys.length > 1 && (
                <div
                  onClick={toggleOpen}
                  className={clsx(
                    '-top-1 right-0 absolute cursor-pointer rounded-full bg-black text-white w-8 h-8 shrink-0 flex items-center justify-center text-2xl z-50 ',
                    isOpened ? '' : ''
                  )}
                >
                  {isOpened ? <BsChevronUp /> : <BsChevronDown />}
                </div>
              )}
            </div>
            {/* accountLoading  ?
      'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx' // : publicKeys ? publicKeys[0] : '-' */}
          </>
        )}
      </Card>
      {publicKeys && publicKeys.length > 1 && isOpened && (
        <div
          className={clsx(
            'absolute bg-white w-full px-4 xl:px-6 py-4 top-[9.5rem] sm:top-[10.5rem] md:top-[10.7rem]  xl:top-[11.5rem] space-y-4 border-2 border-t-0 border-neutral-300 rounded-b-xl z-40',
            publicKeys && publicKeys.length
          )}
        >
          {publicKeys.map((k, ind) => {
            if (ind === 0) {
              return null;
            } else {
              return (
                <div
                  className="truncate text-base sm:text-lg xl:text-xl flex items-center w-10/12 xl:w-11/12"
                  key={k + ind}
                >
                  <div className=" truncate"> {k}</div>
                  <div>
                    <button onClick={() => k && handleCopyValue(k)}>
                      <FaRegCopy
                        className={clsx(
                          'sm:text-lg xl:text-xl ml-2',
                          activeClasses
                        )}
                      />
                    </button>
                  </div>
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};
