import clsx from 'clsx';
import { FiSearch } from 'react-icons/fi';

type CardSearchProps = {
  searchInput: string;
  onChange: (value: string) => void;
  placeholder: string;
};

export const CardSearch = ({
  searchInput,
  onChange,
  placeholder
}: CardSearchProps) => {
  return (
    <div
      className={clsx(
        'mb-4 bg-white rounded-full flex items-center transition-all duration-300 overflow-hidden w-full  shadow-[0px_0px_10px_rgba(50,_50,_50,_12%)] bg-gradient-to-b from-neutral-200/10 via-neutral-200/10 to-neutral-500/30'
      )}
    >
      <div className="m-[1px] w-[calc(100%-2px)] h-[calc(100%-2px)] relative flex items-center bg-white rounded-full pl-4 pr-3 py-2 px-4">
        <FiSearch
          className={clsx(
            'text-lg cursor-pointer transition-all duration-300 z-50 shrink-0'
          )}
        />
        <div
          className={clsx(
            'w-full h-full transition-all duration-300 origin-left flex items-center bg-white rounded-full px-1.5'
          )}
        >
          <input
            id="search"
            placeholder={placeholder}
            className={clsx(
              'block ml-1 rounded-full px-2 py-0.5 text-sm focus:outline-none w-full'
            )}
            value={searchInput}
            onChange={(e) => onChange(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};
