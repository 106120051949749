import { IValidator } from '../../../entities/validator';
import { AddressBlock } from '../../../shared';

export const ValidatorAddress = (validator: IValidator) => {
  return (
    <div className="flex flex-col gap-6 truncate">
      {validator.address && (
        <AddressBlock title="Address" value={validator.address} isAccLink />
      )}
      {validator.consensus_key && (
        <AddressBlock title="Consensus key" value={validator.consensus_key} />
      )}
      {validator.consensus_key_hash && (
        <AddressBlock
          title="Consensus key hash"
          value={validator.consensus_key_hash}
        />
      )}
      {validator.tendermint_key && (
        <AddressBlock title="Tendermint key" value={validator.tendermint_key} />
      )}
    </div>
  );
};
