import { useEffect, useState } from 'react';

export const usePagination = (
  totalPages: number,
  callBack: (nextPage: number) => void
) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [nextDisabled, setNextDisabled] = useState(totalPages === currentPage);
  const [prevDisabled, setPrevDisabled] = useState(currentPage === 1);

  const handleGetNextPage = () => {
    if (nextDisabled) return;

    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);

    if (nextPage === 2) setPrevDisabled(false);
    callBack(nextPage);
  };

  const handleGetPrevPage = () => {
    if (prevDisabled) return;
    const prevPage = currentPage - 1;
    setCurrentPage(prevPage);
    callBack(prevPage);
  };

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
    callBack(page);
  };

  useEffect(() => {
    if (currentPage === totalPages) setNextDisabled(true);
    if (currentPage < totalPages - 1 && nextDisabled) setNextDisabled(false);

    if (currentPage === 1) setPrevDisabled(true);
    if (currentPage > 1 && prevDisabled) setPrevDisabled(false);
  }, [currentPage]);

  return {
    currentPage,
    totalPages,
    handleGetNextPage,
    handleGetPrevPage,
    handleChangePage,
    nextDisabled,
    prevDisabled
  };
};
