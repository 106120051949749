import { StoreApi, create } from 'zustand';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { ITransaction, logger } from '../../../shared';
import { transactionApi } from './transactionApi';
import { TTransaction } from '../model';

type Store = {
  transactions: TTransaction[] | null;
  wrapperTxs: TTransaction[] | null;
  decryptedTxs: TTransaction[] | null;
  transaction: ITransaction | null;
  transactionsLoading: boolean;
  transactionsError: string | null;
};

type Actions = {
  getTransactions: (height: number) => Promise<void>;
  getTransactionData: (hash: string) => Promise<void>;
};

type TransactionStore = Store & Actions;

export const transactionStore = create<TransactionStore>(
  (
    set: StoreApi<TransactionStore>['setState'],
    get: StoreApi<TransactionStore>['getState']
  ) => ({
    transactions: null,
    wrapperTxs: null,
    decryptedTxs: null,
    transaction: null,
    transactionsLoading: true,
    transactionsError: null,
    getTransactions: async (height) => {
      set({
        transactionsError: null
      });
      try {
        const transactions = await transactionApi.getTransactions(height);

        const wrapperTxs = transactions.filter((t) => t.tx_type === 'Wrapper');
        const decryptedTxs = transactions.filter(
          (t) => t.tx_type === 'Decrypted'
        );

        set({ transactions, wrapperTxs, decryptedTxs });
      } catch (error) {
        if (error instanceof AxiosError) {
          console.error(error);
          const message =
            'Something went wrong when we tried to get transactions';
          set({
            transactionsError: message
          });
          toast.error(message);
        }
      } finally {
        set({ transactionsLoading: false });
      }
    },
    getTransactionData: async (hash) => {
      set({
        transaction: null,
        transactionsError: null,
        transactionsLoading: true
      });
      try {
        const transaction = await transactionApi.getTransactionData(hash);

        set({ transaction });
      } catch (error) {
        if (error instanceof AxiosError) {
          console.error(error);
          const message =
            'Something went wrong when we tried to get transactions';
          set({
            transactionsError: message
          });
          toast.error(message);
        }
      } finally {
        set({ transactionsLoading: false });
      }
    }
  })
);
